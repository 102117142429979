import { Box } from "@mui/material";
import { KeyboardArrowRightIcon } from "components/Icons/KeyboardArrowRightIcon";
import {
  ContractStatus,
  GetExplorerDataQuery,
  GetExplorerDataQueryVariables,
  ProductInstanceStatus,
  ProductStatus,
  ProductUserRoleStatus,
  ProjectStatus,
} from "generated/graphql";
import { getTimezoneOffset } from "helpers/timezones";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import { useMemo, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { EntityExplorer } from "./components/EntityExplorer";
import { ExplorerEntity } from "./Explorer.decl";
import { getExplorerDataQuery } from "./Explorer.query";
import uniqBy from "lodash.uniqby";

export type ExplorerProps = {
  selectedProjectId?: string;
  selectedContractId?: string;
  selectedProductId?: string;
  selectedProductInstanceId?: string;
  loading?: boolean;
  onProjectSelectionChange?: (selectedProjectId: string) => void;
  onContractSelectionChange?: (selectedContractId?: string) => void;
  onProductSelectionChange?: (selectedProductId?: string) => void;
  onProductInstanceSelectionChange?: (
    selectedProductInstanceId?: string
  ) => void;
};

export const Explorer: React.FC<ExplorerProps> = ({
  selectedProjectId,
  selectedContractId,
  selectedProductId,
  selectedProductInstanceId,
  loading: outerLoading,
  onProjectSelectionChange,
  onContractSelectionChange,
  onProductSelectionChange,
  onProductInstanceSelectionChange,
}) => {
  const { authenticatedUser, loading: authenticatedUserLoading } =
    useContext(GlobalContext);
  const { t } = useTranslation();

  const [
    fetchExplorerData,
    { data: explorerData, loading: getExplorerDataLoading },
  ] = useGraphLazyQuery<GetExplorerDataQuery, GetExplorerDataQueryVariables>(
    getExplorerDataQuery
  );

  const loading =
    outerLoading || authenticatedUserLoading || getExplorerDataLoading;

  const projects = useMemo(() => {
    const projects = explorerData?.user?.roles.items
      .filter((role) => role.status === ProductUserRoleStatus.Active)
      .map((role) => role.productInstance.contract.project);
    const uniqProjects = projects?.filter(
      (project, index) =>
        projects.findIndex((innerProject) => innerProject.id === project.id) ===
        index
    );

    return (
      uniqProjects
        ?.filter((project) => project.status !== ProjectStatus.Removed)
        .map((project) => ({
          id: project.id,
          listItemLeftText: project.friendlyName,
          status: project.status,
        }))
        .sort((p1, p2) =>
          p1.listItemLeftText.localeCompare(p2.listItemLeftText)
        ) || []
    );
  }, [explorerData]);

  const contracts = useMemo(() => {
    const contracts = explorerData?.user?.roles.items.map(
      (role) => role.productInstance.contract
    );
    const uniqContracts = uniqBy(contracts, "id");

    const contractsOfSelectedProject = uniqContracts?.filter(
      (contract) => contract.projectId === selectedProjectId
    );

    return (
      contractsOfSelectedProject
        ?.filter((contract) => contract.status !== ContractStatus.Removed)
        .map((contract) => ({
          id: contract.id,
          listItemLeftText: contract.friendlyName,
          listItemRightText: `(${getTimezoneOffset(contract.timeZone)})`,
          status: contract.status,
        }))
        .sort((c1, c2) =>
          c1.listItemLeftText.localeCompare(c2.listItemLeftText)
        ) || []
    );
  }, [explorerData, selectedProjectId]);

  const products = useMemo(() => {
    const productInstances = explorerData?.user?.roles.items.map(
      (role) => role.productInstance
    );
    const productInstancesOfSelectedContract = selectedContractId
      ? productInstances?.filter((pi) => pi.contractId === selectedContractId)
      : [];

    const productsOfProductInstancesOfSelectedContract =
      productInstancesOfSelectedContract?.map((pi) => pi.product);
    const uniqProducts = productsOfProductInstancesOfSelectedContract?.filter(
      (product, index) =>
        productsOfProductInstancesOfSelectedContract.indexOf(product) === index
    );

    return (
      uniqProducts
        ?.filter((product) => product.status !== ProductStatus.Removed)
        .map((product) => ({
          id: product.id,
          listItemLeftText: product.name,
          status: product.status,
        }))
        .sort((p1, p2) =>
          p1.listItemLeftText.localeCompare(p2.listItemLeftText)
        ) || []
    );
  }, [explorerData, selectedContractId]);

  const productInstances = useMemo(() => {
    const productInstances = explorerData?.user?.roles.items.map(
      (role) => role.productInstance
    );
    const uniqProductInstances = productInstances?.filter(
      (productInstance, index) =>
        productInstances.indexOf(productInstance) === index
    );
    const productInstancesOfSelectedProduct = selectedProductId
      ? uniqProductInstances?.filter(
          (productInstance) => productInstance.productId === selectedProductId
        )
      : [];

    const productInstancesOfSelectedContract = selectedContractId
      ? productInstancesOfSelectedProduct?.filter(
          (productInstance) => productInstance.contractId === selectedContractId
        )
      : [];

    return (
      productInstancesOfSelectedContract
        ?.filter(
          (productInstance) =>
            productInstance.status !== ProductInstanceStatus.Removed
        )
        .map((productInstance) => ({
          id: productInstance.id,
          listItemLeftText: productInstance.description,
          status: productInstance.status,
        }))
        .sort((p1, p2) =>
          p1.listItemLeftText.localeCompare(p2.listItemLeftText)
        ) || []
    );
  }, [explorerData, selectedProductId, selectedContractId]);

  const handleSelectProject = (item: ExplorerEntity) => {
    onProjectSelectionChange?.(item.id);
  };

  const handleSelectContract = (item: ExplorerEntity) => {
    onContractSelectionChange?.(item.id);
  };

  const handleSelectProduct = (item: ExplorerEntity) => {
    onProductSelectionChange?.(item.id);
  };

  const handleProductInstanceSelectionChange = (item: ExplorerEntity) => {
    onProductInstanceSelectionChange?.(item.id);
  };

  useEffect(() => {
    if (authenticatedUser?.id) {
      fetchExplorerData({ variables: { id: authenticatedUser.id } });
    }
  }, [authenticatedUser, fetchExplorerData]);

  useEffect(() => {
    if (productInstances.length === 1 && !getExplorerDataLoading) {
      if (!selectedProductInstanceId) {
        onProductInstanceSelectionChange?.(productInstances[0].id);
      }
    }
  }, [
    productInstances,
    getExplorerDataLoading,
    onProductInstanceSelectionChange,
    selectedProductInstanceId,
  ]);

  /**
   * Note: Explorer data is taken from a separate query than user permissions (authenticatedUser)
   * Explorer data is fetched every time the component is mounted. Refetching authenticatedUser as well
   * so that permissions & access are in sync.
   * TODO: merge the two queries. Have Explorer rely only on authenticatedUser data and refresh it every time the cmp mounts.
   * Now it's too risky of a move
   */
  // useEffect(() => {
  //   refetchAuthenticatedUser();
  // }, [refetchAuthenticatedUser]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      boxSizing="border-box"
    >
      <EntityExplorer
        items={projects}
        onSelectionChange={handleSelectProject}
        entityType={t("common.labels.project")}
        selectedEntityId={selectedProjectId}
        loading={loading}
        dataTestId="project-selector"
      />
      <Box px={3}>
        <KeyboardArrowRightIcon />
      </Box>
      <EntityExplorer
        items={contracts}
        onSelectionChange={handleSelectContract}
        entityType={t("common.labels.contract")}
        selectedEntityId={selectedContractId}
        rightListHeader={t("common.labels.timezone")}
        disabled={!selectedProjectId}
        loading={loading}
        dataTestId="contract-selector"
      />
      <Box px={3}>
        <KeyboardArrowRightIcon />
      </Box>
      <EntityExplorer
        items={products}
        onSelectionChange={handleSelectProduct}
        entityType={t("common.labels.product")}
        selectedEntityId={selectedProductId}
        flyoutPlacement={productInstances.length <= 1 ? "right" : undefined}
        disabled={!selectedContractId}
        loading={loading}
        dataTestId="product-selector"
      />
      {productInstances.length > 1 ? (
        <>
          <Box px={3}>
            <KeyboardArrowRightIcon />
          </Box>
          <EntityExplorer
            items={productInstances}
            onSelectionChange={handleProductInstanceSelectionChange}
            entityType={t("common.labels.productInstance")}
            selectedEntityId={selectedProductInstanceId}
            flyoutPlacement="right"
            dataTestId="product-instance-selector"
            disabled={!selectedProductId}
            loading={loading}
          />
        </>
      ) : null}
    </Box>
  );
};
