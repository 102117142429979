import {
  DailyDiaryPresetSection,
  DailyDiarySearchHseRecord,
} from "generated/graphql";
import { DailyDiarySectionDataGrid } from "components/StyledDataGrid";
import { useCallback, useEffect, useImperativeHandle, useState } from "react";
import {
  GridFilterModel,
  GridLogicOperator,
  GridRowId,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";
import { NoRowsOverlay } from "containers/Projects/containers/DailyDiary/components/NoRowsOverlay";
import { useTranslation } from "react-i18next";
import { useColumns } from "./HSESectionSearchResults.constants";
import { useRecordActiveAttachments } from "../../../hooks/useRecordActiveAttachments";
import { DDSection } from "containers/Projects/containers/DailyDiary/components/DailyDiaryInterpretor/DDSectionsInterpretor";
import { DailyDiaryRecordAttachmentsModal } from "containers/Projects/containers/DailyDiary/components/DailyDiaryRecordAttachmentsModal/DailyDiaryRecordAttachmentsModal";
import { toRecordJSON } from "./HSESectionSearchResults.utils";
import { ResultsSectionPublicApi } from "../../../DailyDiariesSearch.decl";
import { exportToExcel } from "helpers/exportToExcel";

type HSESectionSearchResultsProps = {
  section: DailyDiaryPresetSection;
  records: DailyDiarySearchHseRecord[];
  loading?: boolean;
  onFetchMore: () => void;
  apiRef?: React.Ref<ResultsSectionPublicApi>;
  canExportChange: (state: boolean) => void;
};

export const HSESectionSearchResults: React.FC<
  HSESectionSearchResultsProps
> = ({ records, loading, onFetchMore, apiRef, canExportChange }) => {
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState<DailyDiarySearchHseRecord>();

  const {
    attachments: selectedRowAttachments,
    loading: selectedRowAttachmentsLoading,
  } = useRecordActiveAttachments(
    selectedRow?.recordId,
    selectedRow?.diaryId,
    DDSection.HSE,
    selectedRow?.diaryRevisionId
  );

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();

  const handleAttachmentsClick = (rowId: GridRowId) => {
    setSelectedRow(records.find((record) => record.recordId === rowId));
  };

  const columns = useColumns({
    onAttachmentsClick: handleAttachmentsClick,
  });

  const handleExportToExcel = useCallback(() => {
    const columns = [
      { header: t("common.labels.status"), key: "status" },
      { header: t("common.labels.date"), key: "date" },
      {
        header: t("common.labels.description"),
        key: "description",
      },
      {
        header: t("Projects.DailyDiaries.HSE.HSEType"),
        key: "hseType",
      },
      {
        header: t("common.labels.createdBy"),
        key: "creator",
      },
      // {
      //   header: "",
      //   key: "attachments",
      // },
    ];

    const rowsToExport = records
      .filter((record) => (selectionModel || []).indexOf(record.recordId) >= 0)
      .map((record) => ({
        ...record,
        status: record.diaryStatus,
        date: record.diaryDate,
        description: record.description ?? "",
        hseType: record.hseType ?? "",
        // attachments: record.attachmentCount,
      }));

    exportToExcel(
      `${t("Projects.DailyDiariesSearch.title")}-${t(
        "Projects.DailyDiaries.sections.HSE"
      )}`,
      columns,
      rowsToExport
    );
  }, [records, selectionModel, t]);

  useEffect(() => {
    canExportChange(!!selectionModel?.length);
  }, [selectionModel, canExportChange]);

  useImperativeHandle(
    apiRef,
    () => ({
      exportRecords: handleExportToExcel,
    }),
    [handleExportToExcel]
  );

  return (
    <>
      {selectedRow && (
        <DailyDiaryRecordAttachmentsModal
          open={!!selectedRow}
          loading={selectedRowAttachmentsLoading}
          attachments={selectedRowAttachments ?? []}
          onClose={() => setSelectedRow(undefined)}
          recordJSON={toRecordJSON(selectedRow, t)}
          title={t("Projects.DailyDiaries.HSE.hse")}
        />
      )}
      <DailyDiarySectionDataGrid
        rows={records}
        columns={columns}
        getRowId={(rowData: DailyDiarySearchHseRecord) => rowData.recordId}
        loading={loading}
        sortingMode="client"
        filterMode="client"
        filterModel={filterModel}
        slots={{
          noRowsOverlay: () => (
            <NoRowsOverlay
              title={t("Projects.DailyDiariesSearch.noResults")}
              hideSubtitle
            />
          ),
        }}
        onFilterModelChange={setFilterModel}
        onRowsScrollEnd={onFetchMore}
        rowSelectionModel={selectionModel}
        checkboxSelection
        autosizeOptions={{
          includeHeaders: true,
          includeOutliers: false,
        }}
        onRowSelectionModelChange={setSelectionModel}
        rowSelection
        disableRowSelectionOnClick
        hideFooter
      />
    </>
  );
};
