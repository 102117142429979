import { DailyDiarySearchGeneralRecord } from "generated/graphql";
import { TFunction } from "i18next";

export const toRecordJSON = (
  record: DailyDiarySearchGeneralRecord,
  t: TFunction
) => {
  return {
    [t("common.labels.description")]: record.description ?? "",
    [t("common.labels.createdBy")]: record.creator,
  };
};
