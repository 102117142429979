import { gql } from "@apollo/client";
import { dailyDiaryItemFields } from "graphql/fragments/dailyDiaryItem.fragment";

// Note: if Diary is not existing and is waived directly, it is first created, then waived.
// In that case, we need the diary information to track it in Intercom events
export const waiveDailyDiaryItemMutation = gql`
  ${dailyDiaryItemFields}

  mutation waiveDailyDiaryItem($input: WaiveDailyDiaryInput!) {
    waiveDailyDiaryItem(input: $input) {
      __typename
      ... on DailyDiaryItem {
        ...DailyDiaryItemFields
      }
    }
  }
`;
