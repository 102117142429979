import { Box } from "@mui/material";
import {
  AuthorizationWorkflowAuditStatus,
  AuthorizationWorkflowAuditTrail,
  AuthorizationWorkflowLevel,
} from "generated/graphql";
import { LevelBadge } from "./LevelBadge";
import { LevelRuleBanner } from "./LevelRuleBanner";
import { AuthorizationLevelOverview } from "./AuthorizationLevelOverview";

export type AuthorizationLevelProps = {
  authLevel: AuthorizationWorkflowLevel;
  settledTrails: AuthorizationWorkflowAuditTrail[];
  levelStatus: AuthorizationWorkflowAuditStatus;
  levelNotStarted?: boolean;
  authAuditStatus: AuthorizationWorkflowAuditStatus;
  contractTimezone: string;
  dailyDiaryVariant?: boolean;
};

export type LocalAuthorizationWorkflowAuditStatus =
  | AuthorizationWorkflowAuditStatus
  | "NotStarted";

export const AuthorizationLevel: React.FC<AuthorizationLevelProps> = ({
  authLevel,
  settledTrails,
  levelStatus,
  authAuditStatus,
  contractTimezone,
  levelNotStarted,
  dailyDiaryVariant = false,
}) => {
  const authAuditSettled = [
    AuthorizationWorkflowAuditStatus.Authorized,
    AuthorizationWorkflowAuditStatus.Denied,
    AuthorizationWorkflowAuditStatus.Removed,
  ].includes(authAuditStatus);

  const levelLocalStatus = levelNotStarted
    ? "NotStarted"
    : authAuditSettled &&
      levelStatus === AuthorizationWorkflowAuditStatus.InProgress
    ? authAuditStatus
    : levelStatus;

  return (
    <Box>
      <LevelBadge status={levelLocalStatus} orderNumber={authLevel.sequence} />
      <Box mt={2} sx={{ background: "white" }}>
        <LevelRuleBanner mode={authLevel.mode} mb={1} />
        <AuthorizationLevelOverview
          levelStatus={levelLocalStatus}
          authAuditStatus={authAuditStatus}
          authLevel={authLevel}
          settledTrails={settledTrails}
          contractTimezone={contractTimezone}
          dailyDiaryVariant={dailyDiaryVariant}
        />
      </Box>
    </Box>
  );
};
