import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const NoResultsView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
    >
      <Typography variant="p2" fontWeight={600} color="grey.800" mb={0.5}>
        {t("Projects.DailyDiariesSearch.noResults")}
      </Typography>
    </Box>
  );
};
