import { Box, Stack } from "@mui/material";
import {
  DailyDiaryPresetFetchMode,
  useDailyDiaryPreset,
} from "hooks/useDailyDiaryPreset";
import {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { WorkSectionSearchResults } from "./sections/WorkSectionSearchResults/WorkSectionSearchResults";
import {
  DailyDiarySearchDelayRecord,
  DailyDiarySearchGeneralRecord,
  DailyDiarySearchHseRecord,
  DailyDiarySearchRecordsInput,
  DailyDiarySearchRecordsMetadataQuery,
  DailyDiarySearchWeatherRecord,
  DailyDiarySearchWorkRecord,
} from "generated/graphql";
import { EmptyState } from "./EmptyState";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { TabsWithCount } from "containers/Projects/components/TabsWithCount/TabsWithCount";
import { useTranslation } from "react-i18next";
import { DDSection } from "../../DailyDiary/components/DailyDiaryInterpretor/DDSectionsInterpretor";
import { NoResultsView } from "./NoResultsView";
import { WeatherSectionSearchResults } from "./sections/WeatherSectionSearchResults/WeatherSectionSearchResults";
import { DelaySectionSearchResults } from "./sections/DelaySectionSearchResults/DelaySectionSearchResults";
import { HSESectionSearchResults } from "./sections/HSESectionSearchResults/HSESectionSearchResults";
import { GeneralSectionSearchResults } from "./sections/GeneralSectionSearchResults/GeneralSectionSearchResults";
import {
  DailyDiarySearchResourceRecord,
  ResourceSectionSearchResults,
} from "./sections/ResourceSectionSearchResults/ResourceSectionSearchResults";
import { useDailyDiariesSearch } from "../hooks/useDailyDiariesSearch";
import { ResultsSectionPublicApi } from "../DailyDiariesSearch.decl";

type SearchResultsProps = {
  productInstanceId: string;
  searchResultsMetadataLoading: boolean;
  searchFormInput?: DailyDiarySearchRecordsInput;
  searchResultsMetadata?: DailyDiarySearchRecordsMetadataQuery;
  selectedTabId: string;
  apiRef?: React.Ref<ResultsSectionPublicApi>;
  onCanExportChange: (state: boolean) => void;
  onTabSelectionChange: (tabId: string) => void;
};

export const SearchResults: React.FC<SearchResultsProps> = ({
  productInstanceId,
  selectedTabId,
  searchFormInput,
  searchResultsMetadata,
  searchResultsMetadataLoading,
  apiRef,
  onCanExportChange,
  onTabSelectionChange,
}) => {
  const { t } = useTranslation();
  const workResultsRef = useRef<ResultsSectionPublicApi>(null);
  const [canExportWorkRecords, setCanExportWorkRecords] = useState<boolean>();
  const weatherResultsRef = useRef<ResultsSectionPublicApi>(null);
  const [canExportWeatherRecords, setCanExportWeatherRecords] =
    useState<boolean>();
  const resourceResultsRef = useRef<ResultsSectionPublicApi>(null);
  const [canExportResourceRecords, setCanExportResourceRecords] =
    useState<boolean>();
  const hseResultsRef = useRef<ResultsSectionPublicApi>(null);
  const [canExportHseRecords, setCanExportHseRecords] = useState<boolean>();
  const generalResultsRef = useRef<ResultsSectionPublicApi>(null);
  const [canExportGeneralRecords, setCanExportGeneralRecords] =
    useState<boolean>();
  const delayResultsRef = useRef<ResultsSectionPublicApi>(null);
  const [canExportDelayRecords, setCanExportDelayRecords] = useState<boolean>();

  const { dailyDiaryPreset, loading: diaryPresetLoading } = useDailyDiaryPreset(
    DailyDiaryPresetFetchMode.ByProductInstanceId,
    undefined,
    productInstanceId
  );

  const {
    fetchMoreSearchResults,
    fetchSearchResults,
    searchResults,
    isInitialBatchLoading,
    loading: searchResultsLoading,
  } = useDailyDiariesSearch();

  const sectionNames = useMemo(
    () => dailyDiaryPreset?.sections.map((section) => section.name),
    [dailyDiaryPreset]
  );

  const selectedSectionFull = useMemo(() => {
    return dailyDiaryPreset?.sections.find(
      (section) => section.name === selectedTabId
    );
  }, [selectedTabId, dailyDiaryPreset]);

  const tabs = useMemo(() => {
    return (
      sectionNames?.map((section) => ({
        id: section,
        label: t(`Projects.DailyDiaries.sections.${section}`),
        count:
          searchResultsMetadata?.dailyDiarySearchRecords.sectionFacets.find(
            (facet) =>
              facet.facetName === section || facet.facetName + "s" === section // TODO: remove second condition after Delays section has Delays facet
          )?.facetCount ?? 0,
      })) ?? []
    );
  }, [searchResultsMetadata, t, sectionNames]);

  const handleFetchMoreResults = () => {
    if (
      searchResults &&
      searchResults.dailyDiarySearchRecords.page <
        searchResults.dailyDiarySearchRecords.totalPages
    ) {
      fetchMoreSearchResults({
        variables: {
          input: {
            ...searchFormInput,
            section: selectedTabId,
            page: searchResults.dailyDiarySearchRecords.page + 1,
          },
        },
        updateQuery: (oldData, { fetchMoreResult: newData }) => ({
          ...newData,
          dailyDiarySearchRecords: {
            ...newData.dailyDiarySearchRecords,
            items: [
              ...(oldData.dailyDiarySearchRecords.items ?? []),
              ...(newData.dailyDiarySearchRecords.items ?? []),
            ],
          },
        }),
      });
    }
  };

  const selectedSectionTotalCount = useMemo(() => {
    return searchResultsMetadata?.dailyDiarySearchRecords.sectionFacets.find(
      (facet) => {
        const sameName = facet.facetName === selectedTabId;
        const missingS = facet.facetName + "s" === selectedTabId; // TODO: remove this extra check when Delays section has Delays facet
        return sameName || missingS;
      }
    )?.facetCount;
  }, [searchResultsMetadata, selectedTabId]);

  const handleExportToExcel = useCallback(() => {
    switch (selectedTabId) {
      case DDSection.Work:
        workResultsRef.current?.exportRecords();
        break;
      case DDSection.Weather:
        weatherResultsRef.current?.exportRecords();
        break;
      case DDSection.Equipment:
      case DDSection.Manpower:
        resourceResultsRef.current?.exportRecords();
        break;
      case DDSection.HSE:
        hseResultsRef.current?.exportRecords();
        break;
      case DDSection.General:
        generalResultsRef.current?.exportRecords();
        break;
      case DDSection.Delays:
        delayResultsRef.current?.exportRecords();
        break;

      default:
        break;
    }
  }, [selectedTabId]);

  useImperativeHandle(
    apiRef,
    () => ({
      exportRecords: handleExportToExcel,
    }),
    [handleExportToExcel]
  );

  useEffect(() => {
    switch (selectedTabId) {
      case DDSection.Work:
        onCanExportChange(!!canExportWorkRecords);
        break;
      case DDSection.Weather:
        onCanExportChange(!!canExportWeatherRecords);
        break;
      case DDSection.Manpower:
      case DDSection.Equipment:
        onCanExportChange(!!canExportResourceRecords);
        break;
      case DDSection.HSE:
        onCanExportChange(!!canExportHseRecords);
        break;
      case DDSection.General:
        onCanExportChange(!!canExportGeneralRecords);
        break;
      case DDSection.Delays:
        onCanExportChange(!!canExportDelayRecords);
        break;
      default:
        break;
    }
  }, [
    canExportWorkRecords,
    canExportWeatherRecords,
    canExportResourceRecords,
    canExportHseRecords,
    canExportGeneralRecords,
    canExportDelayRecords,
    selectedTabId,
    onCanExportChange,
  ]);

  useEffect(() => {
    if (tabs && tabs.length && !selectedTabId) {
      onTabSelectionChange(tabs[0].id);
    }
  }, [tabs, selectedTabId, onTabSelectionChange]);

  useEffect(() => {
    if (selectedTabId && searchFormInput && selectedSectionTotalCount) {
      fetchSearchResults({
        variables: {
          input: {
            ...searchFormInput,
            section: selectedTabId,
          },
        },
      });
    }
  }, [
    selectedTabId,
    searchFormInput,
    fetchSearchResults,
    selectedSectionTotalCount,
  ]);

  const searchResultsItems = useMemo(() => {
    return selectedSectionTotalCount
      ? searchResults?.dailyDiarySearchRecords.items ?? []
      : [];
  }, [searchResults?.dailyDiarySearchRecords.items, selectedSectionTotalCount]);

  if (searchResultsMetadataLoading) {
    return <CenteredLoadingIndicator />;
  }

  if (!searchResultsMetadata) {
    return <EmptyState />;
  }

  if (!searchResultsMetadata.dailyDiarySearchRecords.totalItems) {
    return <NoResultsView />;
  }

  return (
    <Stack
      direction="column"
      spacing={1}
      width="100%"
      overflow="hidden"
      flex={1}
    >
      <Box px={3}>
        {tabs.length ? (
          <TabsWithCount
            tabs={tabs}
            selectedTabId={selectedTabId}
            onSelectedTabChange={onTabSelectionChange}
          />
        ) : null}
      </Box>
      {isInitialBatchLoading ? (
        <Box
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="center"
        >
          <CenteredLoadingIndicator />
        </Box>
      ) : (
        selectedSectionFull && (
          <Box width="100%" flex={1} overflow="auto">
            {selectedTabId === DDSection.Work ? (
              <WorkSectionSearchResults
                records={searchResultsItems as DailyDiarySearchWorkRecord[]}
                section={selectedSectionFull}
                loading={
                  searchResultsMetadataLoading ||
                  diaryPresetLoading ||
                  searchResultsLoading
                }
                apiRef={workResultsRef}
                canExportChange={setCanExportWorkRecords}
                onFetchMore={handleFetchMoreResults}
              />
            ) : selectedTabId === DDSection.Weather ? (
              <WeatherSectionSearchResults
                section={selectedSectionFull}
                records={searchResultsItems as DailyDiarySearchWeatherRecord[]}
                loading={
                  searchResultsMetadataLoading ||
                  diaryPresetLoading ||
                  searchResultsLoading
                }
                apiRef={weatherResultsRef}
                canExportChange={setCanExportWeatherRecords}
                onFetchMore={handleFetchMoreResults}
              />
            ) : selectedTabId === DDSection.Delays ? (
              <DelaySectionSearchResults
                section={selectedSectionFull}
                records={searchResultsItems as DailyDiarySearchDelayRecord[]}
                loading={
                  searchResultsMetadataLoading ||
                  diaryPresetLoading ||
                  searchResultsLoading
                }
                apiRef={delayResultsRef}
                canExportChange={setCanExportDelayRecords}
                onFetchMore={handleFetchMoreResults}
              />
            ) : selectedTabId === DDSection.HSE ? (
              <HSESectionSearchResults
                section={selectedSectionFull}
                records={searchResultsItems as DailyDiarySearchHseRecord[]}
                loading={
                  searchResultsMetadataLoading ||
                  diaryPresetLoading ||
                  searchResultsLoading
                }
                apiRef={hseResultsRef}
                canExportChange={setCanExportHseRecords}
                onFetchMore={handleFetchMoreResults}
              />
            ) : selectedTabId === DDSection.General ? (
              <GeneralSectionSearchResults
                section={selectedSectionFull}
                records={searchResultsItems as DailyDiarySearchGeneralRecord[]}
                onFetchMore={handleFetchMoreResults}
                loading={
                  searchResultsMetadataLoading ||
                  diaryPresetLoading ||
                  searchResultsLoading
                }
                apiRef={generalResultsRef}
                canExportChange={setCanExportGeneralRecords}
              />
            ) : selectedTabId === DDSection.Equipment ||
              selectedTabId === DDSection.Manpower ? (
              <ResourceSectionSearchResults
                section={selectedSectionFull}
                records={searchResultsItems as DailyDiarySearchResourceRecord[]}
                sectionType={selectedTabId}
                onFetchMore={handleFetchMoreResults}
                apiRef={resourceResultsRef}
                canExportChange={setCanExportResourceRecords}
                loading={
                  searchResultsMetadataLoading ||
                  diaryPresetLoading ||
                  searchResultsLoading
                }
              />
            ) : null}
          </Box>
        )
      )}
    </Stack>
  );
};
