import {
  CompanyLookupCollection,
  DailyDiaryPresetSection,
  DailyDiaryWeatherRecord,
} from "generated/graphql";
import { getUserName } from "helpers/miscelaneous";
import { TFunction } from "i18next";
import { timeStripMinutes } from "../../../utilts";

export const unitValueFormatter = (
  value: string | null | undefined,
  fieldName: string,
  section: DailyDiaryPresetSection,
  t: TFunction
) =>
  value !== null && value !== undefined
    ? `${value} ${t(
        `Projects.DailyDiaries.Units.${
          section.fields.find((field) => field.name === fieldName)?.unit
        }`
      )}`
    : "";

export const toRecordJSON = (
  weatherRecord: DailyDiaryWeatherRecord,
  localLookups: CompanyLookupCollection[],
  section: DailyDiaryPresetSection,
  t: TFunction
) => {
  return {
    [t("Projects.DailyDiaries.Weather.conditions")]:
      localLookups
        .find((lkp) => lkp.id === weatherRecord.conditionsLookupId)
        ?.options.items.find(
          (option) => option.id === weatherRecord.conditionsOptionId
        )?.value ?? "",
    [t("Projects.DailyDiaries.Weather.temperature")]: unitValueFormatter(
      weatherRecord.temperature ? `${weatherRecord.temperature}` : null,
      "Temperature",
      section,
      t
    ),
    [t("Projects.DailyDiaries.Weather.rainfall")]: unitValueFormatter(
      weatherRecord.rainFall ? `${weatherRecord.rainFall}` : null,
      "Rainfall",
      section,
      t
    ),
    [t("Projects.DailyDiaries.Weather.windSpeed")]: unitValueFormatter(
      weatherRecord.windSpeed ? `${weatherRecord.windSpeed}` : null,
      "WindSpeed",
      section,
      t
    ),
    [t("Projects.DailyDiaries.Weather.humidity")]: unitValueFormatter(
      weatherRecord.humidity ? `${weatherRecord.humidity}` : null,
      "Humidity",
      section,
      t
    ),
    [t("Projects.DailyDiaries.Weather.measuredAt")]: timeStripMinutes(
      weatherRecord.timeOfMeasurement
    ),
    [t("common.labels.createdBy")]: getUserName(weatherRecord.creator),
  };
};
