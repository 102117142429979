import { Box, Color, css, styled } from "@mui/material";

export const AttachmentsCellContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "clickable",
})<{ clickable?: boolean }>(
  ({ theme, clickable }) => css`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    border-bottom: 1px solid transparent;

    ${clickable
      ? css`
          cursor: pointer;
          &:hover {
            border-bottom-color: ${(
              theme.palette.secondary as Partial<Color>
            )[700]};
          }
        `
      : ""}
  `
);
