import {
  Box,
  BoxProps,
  CircularProgress,
  CircularProgressProps,
} from "@mui/material";

export type CenteredLoadingIndicatorProps = CircularProgressProps &
  Pick<BoxProps, "margin" | "padding">;

export const CenteredLoadingIndicator: React.FC<
  CenteredLoadingIndicatorProps
> = ({ margin, padding, ...rest }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      width="100%"
      margin={margin}
      padding={padding}
    >
      <CircularProgress {...rest} />
    </Box>
  );
};
