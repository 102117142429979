import {
  Divider,
  Skeleton,
  Stack,
  StackProps,
  Typography,
  useTheme,
} from "@mui/material";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import { UsersFour } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { UsersAccessModal } from "./UsersAccessModal";
import { User } from "generated/graphql";

type UsersAccessProps = {
  usersRoles?: Record<string, User[]>;
  loading?: boolean;
  withDivider?: boolean;
} & Pick<StackProps, "marginRight">;

export const UsersAccess: React.FC<UsersAccessProps> = ({
  usersRoles = {},
  marginRight,
  withDivider = true,
  loading,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { modalVisibility, toggleModalVisibility } = useBasicModal();

  return (
    <>
      <UsersAccessModal
        open={modalVisibility}
        usersRoles={usersRoles}
        onClose={toggleModalVisibility}
      />
      <Stack direction="row" alignItems="center" mr={marginRight}>
        {loading ? (
          <Skeleton
            variant="text"
            sx={{ fontSize: "24px" }}
            width={95}
          ></Skeleton>
        ) : (
          <>
            {withDivider && (
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  my: "12px !important",
                }}
              />
            )}
            <Stack
              direction="row"
              spacing={0.5}
              sx={{ cursor: "pointer" }}
              p={1.25}
              alignItems="center"
              onClick={toggleModalVisibility}
            >
              <UsersFour size={16} color={theme.palette.primary.main} />
              <Typography
                variant="p2"
                color="primary.main"
                sx={{ textDecoration: "underline" }}
              >
                {t("common.labels.usersCount", {
                  count: Object.values(usersRoles).flat().length,
                })}
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};
