import {
  DailyDiaryItem,
  ImportDailyDiaryDelayRecordsMutation,
  ImportDailyDiaryDelayRecordsMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useTranslation } from "react-i18next";
import { useDelayRecords } from "./useDelayRecords";
import { importDailyDiaryDelayRecordsMutation } from "../DelaySection.query";

export const useImportDelayRecordsModal = (dailyDiary?: DailyDiaryItem) => {
  const { t } = useTranslation();

  const { delayRecords, delayRecordsLoading } = useDelayRecords(dailyDiary);

  const [importRecords, { loading: importLoading }] = useGraphMutation<
    ImportDailyDiaryDelayRecordsMutation,
    ImportDailyDiaryDelayRecordsMutationVariables
  >(
    importDailyDiaryDelayRecordsMutation,
    {},
    t("Projects.DailyDiaries.ImportRecordsModal.importSuccess")
  );

  return {
    delayRecords,
    loading: delayRecordsLoading || importLoading,
    importLoading,
    delayRecordsLoading,
    importRecords,
  };
};
