import { dailyDiarySearchRecordsQuery } from "../DailyDiariesSearch.query";
import {
  DailyDiarySearchRecordsQuery,
  DailyDiarySearchRecordsQueryVariables,
} from "generated/graphql";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";

export const useDailyDiariesSearch = () => {
  const [fetchSearchResults, { data: searchResults, fetchMore, loading }] =
    useGraphLazyQuery<
      DailyDiarySearchRecordsQuery,
      DailyDiarySearchRecordsQueryVariables
    >(dailyDiarySearchRecordsQuery, {
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
    });

  return {
    searchResults,
    fetchMoreSearchResults: fetchMore,
    fetchSearchResults,
    loading,
    isInitialBatchLoading: loading && !searchResults,
  };
};
