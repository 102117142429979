import { useCallback, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDiaryStore } from "state-management/store/diaryStore";
import { useGlobalStore } from "state-management/store/globalStore";

// TODO: Prio 1. See the other TODO from the file. Do not use this multiple places. Can cause infite loop. To be solved in 2025
export const useDiaryMetadata = () => {
  const {
    dailyDiaryId,
    setDailyDiaryId,
    revisionId,
    setRevisionId,
    clearStore,
  } = useDiaryStore(
    ({
      dailyDiaryId,
      setDailyDiaryId,
      revisionId,
      setRevisionId,
      dailyDiaryDate,
      setDailyDiaryDate,
      clearStore,
    }) => ({
      dailyDiaryId,
      setDailyDiaryId,
      revisionId,
      setRevisionId,
      dailyDiaryDate,
      setDailyDiaryDate,
      clearStore,
    })
  );

  const { date: dailyDiaryDateUrlParam } = useParams();
  const setGlobalLoading = useGlobalStore(({ setLoading }) => setLoading);

  const [searchParams, setSearchParams] = useSearchParams();
  const revisionIdSearchParam = searchParams.get("revisionId");
  const dailyDiaryIdSearchParam = searchParams.get("dailyDiaryId");

  const changeDailyDiaryId = useCallback(
    (dailyDiaryId: string, preventReload = true) => {
      if (preventReload) {
        // we want to change the queryParameters without triggering a route change and thus a page re-mount process.
        // This usually happens when creating a first record in a Diary
        setDailyDiaryId(dailyDiaryId);

        const newUrl =
          window.location.origin +
          window.location.pathname +
          `?dailyDiaryId=${dailyDiaryId}${
            revisionId ? `&revisionId=${revisionId}` : ""
          }`;
        window.history.replaceState({ path: newUrl }, "", newUrl);
      } else {
        // in theory this is not used right now, but just offering the option to navigate in the React context
        setSearchParams(
          {
            ...(revisionId ? { revisionId } : {}),
            dailyDiaryId,
          },
          { replace: true }
        );
      }
    },
    [setDailyDiaryId, revisionId, setSearchParams]
  );

  const changeRevisionId = useCallback(
    (revisionId: string, preventReload = true) => {
      if (!dailyDiaryId) {
        console.error("Cannot change revisionId to a diary without an ID.");
      }

      if (preventReload) {
        // we want to change the queryParameters without triggering a route change and thus a page re-mount process.
        // This usually happens when creating a first record in a Diary
        setRevisionId(revisionId);

        const newUrl =
          window.location.origin +
          window.location.pathname +
          `?dailyDiaryId=${dailyDiaryId}&revisionId=${revisionId}`;
        window.history.replaceState({ path: newUrl }, "", newUrl);
      } else {
        // in theory this is not used right now, but just offering the option to navigate in the React context
        setSearchParams(
          {
            ...(dailyDiaryId ? { dailyDiaryId } : {}),
            revisionId,
          },
          { replace: true }
        );
      }
    },
    [setRevisionId, dailyDiaryId, setSearchParams]
  );

  const changeDiaryMetadata = useCallback(
    (
      diaryId?: string | null,
      revisionId?: string | null,
      preventReload = true
    ) => {
      if (preventReload) {
        setDailyDiaryId(diaryId);
        setRevisionId(revisionId);
        let queryParams = "";
        if (diaryId) {
          queryParams += `?dailyDiaryId=${diaryId}`;

          // revisionId cannot exist without dailyDiaryId
          if (revisionId) {
            queryParams += `&revisionId=${revisionId}`;
          }
        }

        const newUrl =
          window.location.origin + window.location.pathname + queryParams;
        window.history.replaceState({ path: newUrl }, "", newUrl);
      } else {
        setSearchParams(diaryId && revisionId ? { diaryId, revisionId } : {}, {
          replace: true,
        });
      }
    },
    [setDailyDiaryId, setRevisionId, setSearchParams]
  );

  // TODO: Prio 1. This useEffect is wrong. Everywhere where the hook useDiaryMetadata is used, it'll call this
  // useEffect which will clear the store. Instead, make a dedicated function and export it. Call it from outside
  // only when necessary. Maybe inside DDContextProvider.
  useEffect(() => {
    // resetting DiaryStore when date changes
    clearStore();
    setGlobalLoading(false);
  }, [dailyDiaryDateUrlParam, clearStore, setGlobalLoading]);

  useEffect(() => {
    // if (dailyDiaryId !== dailyDiaryIdSearchParam) {
    setDailyDiaryId(dailyDiaryIdSearchParam);
    // }
  }, [dailyDiaryIdSearchParam, setDailyDiaryId]);

  useEffect(() => {
    // if (revisionId !== revisionIdSearchParam) {
    setRevisionId(revisionIdSearchParam);
    // }
  }, [revisionIdSearchParam, setRevisionId]);

  return {
    dailyDiaryId,
    revisionId,
    changeDailyDiaryId,
    changeRevisionId,
    changeDiaryMetadata,
  };
};
