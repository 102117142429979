import { gql } from "@apollo/client";
import { attachmentFields } from "../EarlyWarningDetails/EarlyWarningDetails.query";

export const dailyDiarySearchRecordsQuery = gql`
  query dailyDiarySearchRecords($input: DailyDiarySearchRecordsInput!) {
    dailyDiarySearchRecords(input: $input) {
      totalItems
      totalPages
      page
      items {
        ... on DailyDiarySearchWeatherRecord {
          recordId
          diaryRevisionId
          diaryId
          diaryNumber
          diaryDate
          diaryStatus
          recordSection
          attachmentCount
          creator
          weatherConditions
          temperature
          rainFall
          windSpeed
          humidity
          timeOfMeasurement
        }

        ... on DailyDiarySearchWorkRecord {
          recordId
          diaryRevisionId
          diaryId
          diaryNumber
          diaryDate
          diaryStatus
          recordSection
          attachmentCount
          creator
          description
          area
          discipline
          team
          activity
          workType
          shift
          amount
          unitOfMeasurement
        }

        ... on DailyDiarySearchDelayRecord {
          recordId
          diaryRevisionId
          diaryId
          diaryNumber
          diaryDate
          diaryStatus
          recordSection
          attachmentCount
          creator
          description
          delayType
          area
          discipline
          team
          shift
          duration
        }

        ... on DailyDiarySearchEquipmentRecord {
          recordId
          diaryRevisionId
          diaryId
          diaryNumber
          diaryDate
          diaryStatus
          recordSection
          attachmentCount
          creator
          resource
          quantity
          hours
          remarks
          company
          team
          shift
        }

        ... on DailyDiarySearchGeneralRecord {
          recordId
          diaryRevisionId
          diaryId
          diaryNumber
          diaryDate
          diaryStatus
          recordSection
          attachmentCount
          creator
          description
        }

        ... on DailyDiarySearchHSERecord {
          recordId
          diaryRevisionId
          diaryId
          diaryNumber
          diaryDate
          diaryStatus
          recordSection
          attachmentCount
          creator
          description
          hseType
        }

        ... on DailyDiarySearchManpowerRecord {
          recordId
          diaryRevisionId
          diaryId
          diaryNumber
          diaryDate
          diaryStatus
          recordSection
          attachmentCount
          creator
          resource
          quantity
          hours
          remarks
          company
          team
          shift
        }
      }
    }
  }
`;
export const dailyDiarySearchRecordsMetadataQuery = gql`
  query dailyDiarySearchRecordsMetadata($input: DailyDiarySearchRecordsInput!) {
    dailyDiarySearchRecords(input: $input) {
      totalItems
      totalPages
      page
      sectionFacets {
        facetName
        facetCount
      }
    }
  }
`;

export const productInstanceStartDateDataQuery = gql`
  query productInstanceStartDateData($id: ID!) {
    productInstance(id: $id) {
      id
      contract {
        id
        startDate
      }
      extraConfig {
        __typename
        ... on DailyDiaryExtraConfig {
          startDate
        }
      }
    }
  }
`;

export const dailyDiaryWeatherRecordsAttachmentsQuery = gql`
  ${attachmentFields}

  query dailyDiaryWeatherRecordsAttachments(
    $dailyDiaryId: ID!
    $revisionId: ID
  ) {
    dailyDiaryWeatherRecords(
      dailyDiaryId: $dailyDiaryId
      revisionId: $revisionId
    ) {
      id
      attachments {
        ...AttachmentFields
      }
    }
  }
`;

export const dailyDiaryWorkRecordsAttachmentsQuery = gql`
  ${attachmentFields}

  query dailyDiaryWorkRecordsAttachments($dailyDiaryId: ID!, $revisionId: ID) {
    dailyDiaryWorkRecords(
      dailyDiaryId: $dailyDiaryId
      revisionId: $revisionId
    ) {
      id
      attachments {
        ...AttachmentFields
      }
    }
  }
`;
export const dailyDiaryGeneralRecordsAttachmentsQuery = gql`
  ${attachmentFields}

  query dailyDiaryGeneralRecordsAttachments(
    $dailyDiaryId: ID!
    $revisionId: ID
  ) {
    dailyDiaryGeneralRecords(
      dailyDiaryId: $dailyDiaryId
      revisionId: $revisionId
    ) {
      id
      attachments {
        ...AttachmentFields
      }
    }
  }
`;
export const dailyDiaryHSERecordsAttachmentsQuery = gql`
  ${attachmentFields}

  query dailyDiaryHSERecordsAttachments($dailyDiaryId: ID!, $revisionId: ID) {
    dailyDiaryHSERecords(dailyDiaryId: $dailyDiaryId, revisionId: $revisionId) {
      id
      attachments {
        ...AttachmentFields
      }
    }
  }
`;
export const dailyDiaryDelayRecordsAttachmentsQuery = gql`
  ${attachmentFields}

  query dailyDiaryDelayRecordsAttachments($dailyDiaryId: ID!, $revisionId: ID) {
    dailyDiaryDelayRecords(
      dailyDiaryId: $dailyDiaryId
      revisionId: $revisionId
    ) {
      id
      attachments {
        ...AttachmentFields
      }
    }
  }
`;
export const dailyDiaryManpowerRecordsAttachmentsQuery = gql`
  ${attachmentFields}

  query dailyDiaryManpowerRecordsAttachments(
    $dailyDiaryId: ID!
    $revisionId: ID
  ) {
    dailyDiaryManpowerRecords(
      dailyDiaryId: $dailyDiaryId
      revisionId: $revisionId
    ) {
      id
      attachments {
        ...AttachmentFields
      }
    }
  }
`;
export const dailyDiaryEquipmentRecordsAttachmentsQuery = gql`
  ${attachmentFields}

  query dailyDiaryEquipmentRecordsAttachments(
    $dailyDiaryId: ID!
    $revisionId: ID
  ) {
    dailyDiaryEquipmentRecords(
      dailyDiaryId: $dailyDiaryId
      revisionId: $revisionId
    ) {
      id
      attachments {
        ...AttachmentFields
      }
    }
  }
`;

export const productInstanceDetailsLiteQuery = gql`
  query productInstanceDetailsLite($id: ID!) {
    productInstance(id: $id) {
      id
      description
      contract {
        id
        friendlyName
        project {
          id
          friendlyName
        }
      }
    }
  }
`;

// export const dailyDiaryDelayRecordAttachmentsQuery = gql`
//   ${attachmentFields}

//   query dailyDiaryDelayRecordAttachments($id: ID!) {
//     dailyDiaryDelayRecord(id: $id) {
//       id
//       attachments {
//         ...AttachmentFields
//       }
//     }
//   }
// `;

// export const dailyDiaryWorkRecordAttachmentsQuery = gql`
//   ${attachmentFields}

//   query dailyDiaryWorkRecordAttachments($id: ID!) {
//     dailyDiaryWorkRecord(id: $id) {
//       id
//       attachments {
//         ...AttachmentFields
//       }
//     }
//   }
// `;

// export const dailyDiaryWeatherRecordAttachmentsQuery = gql`
//   ${attachmentFields}

//   query dailyDiaryWeatherRecordAttachments($id: ID!) {
//     dailyDiaryWeatherRecord(id: $id) {
//       id
//       attachments {
//         ...AttachmentFields
//       }
//     }
//   }
// `;

// export const dailyDiaryHSERecordAttachmentsQuery = gql`
//   ${attachmentFields}

//   query dailyDiaryHSERecordAttachments($id: ID!) {
//     dailyDiaryHSERecord(id: $id) {
//       id
//       attachments {
//         ...AttachmentFields
//       }
//     }
//   }
// `;

// export const dailyDiaryGeneralRecordAttachmentsQuery = gql`
//   ${attachmentFields}

//   query dailyDiaryGeneralRecordAttachments($id: ID!) {
//     dailyDiaryGeneralRecord(id: $id) {
//       id
//       attachments {
//         ...AttachmentFields
//       }
//     }
//   }
// `;

// export const dailyDiaryResourceRecordAttachmentsQuery = gql`
//   ${attachmentFields}

//   query dailyDiaryResourceRecordAttachments($id: ID!) {
//     dailyDiaryResourceRecord(id: $id) {
//       id
//       attachments {
//         ...AttachmentFields
//       }
//     }
//   }
// `;
