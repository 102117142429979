import {
  DailyDiaryPresetSection,
  DailyDiarySearchDelayRecord,
  DailyDiarySearchWorkRecord,
} from "generated/graphql";
import { DailyDiarySectionDataGrid } from "components/StyledDataGrid";
import { useCallback, useEffect, useImperativeHandle, useState } from "react";
import {
  GridFilterModel,
  GridLogicOperator,
  GridRowId,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";
import { NoRowsOverlay } from "containers/Projects/containers/DailyDiary/components/NoRowsOverlay";
import { useTranslation } from "react-i18next";
import { useDelaySectionCommon } from "containers/Projects/containers/DailyDiary/components/DelaySection/hooks/useDelaySectionCommon";
import { useColumns } from "./DelaySectionSearchResults.constants";
import { DelayOptionalColumn } from "containers/Projects/containers/DailyDiary/components/DelaySection/DelayTable/DelayTable";
import { DailyDiaryRecordAttachmentsModal } from "containers/Projects/containers/DailyDiary/components/DailyDiaryRecordAttachmentsModal/DailyDiaryRecordAttachmentsModal";
import { toRecordJSON } from "./DelaySectionSearchResults.utils";
import { useRecordActiveAttachments } from "../../../hooks/useRecordActiveAttachments";
import { DDSection } from "containers/Projects/containers/DailyDiary/components/DailyDiaryInterpretor/DDSectionsInterpretor";
import { ResultsSectionPublicApi } from "../../../DailyDiariesSearch.decl";
import { timeStripMinutes } from "containers/Projects/containers/DailyDiary/utilts";
import { exportToExcel } from "helpers/exportToExcel";

type DelaySectionSearchResultsProps = {
  section: DailyDiaryPresetSection;
  records: DailyDiarySearchDelayRecord[];
  loading?: boolean;
  onFetchMore: () => void;
  apiRef?: React.Ref<ResultsSectionPublicApi>;
  canExportChange: (state: boolean) => void;
};

export const DelaySectionSearchResults: React.FC<
  DelaySectionSearchResultsProps
> = ({ records, section, loading, onFetchMore, apiRef, canExportChange }) => {
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState<DailyDiarySearchDelayRecord>();

  const {
    attachments: selectedRowAttachments,
    loading: selectedRowAttachmentsLoading,
  } = useRecordActiveAttachments(
    selectedRow?.recordId,
    selectedRow?.diaryId,
    DDSection.Delays,
    selectedRow?.diaryRevisionId
  );

  const { optionalColumns } = useDelaySectionCommon(section);

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();

  const withDisciplineColumn = optionalColumns.includes(
    DelayOptionalColumn.Discipline
  );
  const withAreaColumn = optionalColumns.includes(DelayOptionalColumn.Area);
  const withTeamColumn = optionalColumns.includes(DelayOptionalColumn.Team);
  const withShiftColumn = optionalColumns.includes(DelayOptionalColumn.Shift);

  const handleAttachmentsClick = (rowId: GridRowId) => {
    setSelectedRow(records.find((record) => record.recordId === rowId));
  };

  const columns = useColumns({
    withDisciplineColumn,
    withAreaColumn,
    withShiftColumn,
    withTeamColumn,
    onAttachmentsClick: handleAttachmentsClick,
  });

  const handleExportToExcel = useCallback(() => {
    const columns = [
      { header: t("common.labels.status"), key: "status" },
      { header: t("common.labels.date"), key: "date" },
      { header: t("common.labels.description"), key: "description" },
      { header: t("Projects.DailyDiaries.Delay.delayType"), key: "delayType" },
      ...(withShiftColumn
        ? [{ header: t("Projects.DailyDiaries.Delay.shift"), key: "shift" }]
        : []),
      ...(withAreaColumn
        ? [{ header: t("Projects.DailyDiaries.Delay.area"), key: "area" }]
        : []),
      ...(withDisciplineColumn
        ? [
            {
              header: t("Projects.DailyDiaries.Delay.discipline"),
              key: "discipline",
            },
          ]
        : []),
      ...(withTeamColumn
        ? [{ header: t("Projects.DailyDiaries.Delay.team"), key: "team" }]
        : []),
      {
        header: t("Projects.DailyDiaries.Delay.duration"),
        key: "duration",
      },
      {
        header: t("common.labels.createdBy"),
        key: "creator",
      },
      // {
      //   header: "",
      //   key: "attachments",
      // },
    ];

    const rowsToExport = records
      .filter((record) => (selectionModel || []).indexOf(record.recordId) >= 0)
      .map((record) => ({
        ...record,
        status: record.diaryStatus,
        date: record.diaryDate,
        description: record.description ?? "",
        shift: record.shift ?? "",
        area: record.area ?? "",
        discipline: record.discipline ?? "",
        team: record.team ?? "",
        duration: timeStripMinutes(record.duration),
        // attachments: record.attachmentCount,
      }));

    exportToExcel(
      `${t("Projects.DailyDiariesSearch.title")}-${t(
        "Projects.DailyDiaries.sections.delays"
      )}`,
      columns,
      rowsToExport
    );
  }, [
    records,
    selectionModel,
    t,
    withAreaColumn,
    withDisciplineColumn,
    withShiftColumn,
    withTeamColumn,
  ]);

  useEffect(() => {
    canExportChange(!!selectionModel?.length);
  }, [selectionModel, canExportChange]);

  useImperativeHandle(
    apiRef,
    () => ({
      exportRecords: handleExportToExcel,
    }),
    [handleExportToExcel]
  );

  return (
    <>
      {selectedRow && (
        <DailyDiaryRecordAttachmentsModal
          open={!!selectedRow}
          loading={selectedRowAttachmentsLoading}
          attachments={selectedRowAttachments ?? []}
          onClose={() => setSelectedRow(undefined)}
          recordJSON={toRecordJSON({
            record: selectedRow,
            withAreaColumn,
            withDisciplineColumn,
            withTeamColumn,
            withShiftColumn,
            t,
          })}
          title={t("Projects.DailyDiaries.Delay.delay")}
        />
      )}
      <DailyDiarySectionDataGrid
        rows={records}
        columns={columns}
        getRowId={(rowData: DailyDiarySearchWorkRecord) => rowData.recordId}
        loading={loading}
        sortingMode="client"
        filterMode="client"
        filterModel={filterModel}
        slots={{
          noRowsOverlay: () => (
            <NoRowsOverlay
              title={t("Projects.DailyDiariesSearch.noResults")}
              hideSubtitle
            />
          ),
        }}
        onFilterModelChange={setFilterModel}
        rowSelectionModel={selectionModel}
        checkboxSelection
        onRowSelectionModelChange={setSelectionModel}
        onRowsScrollEnd={onFetchMore}
        autosizeOptions={{
          includeHeaders: true,
          includeOutliers: false,
        }}
        rowSelection
        disableRowSelectionOnClick
        hideFooter
      />
    </>
  );
};
