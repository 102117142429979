import { Grid, Typography, TypographyProps } from "@mui/material";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { User } from "generated/graphql";
import { getUserName } from "helpers/miscelaneous";
import { useTranslation } from "react-i18next";
import { DateWithTimeTooltip } from "containers/Projects/components/DateWithTimeTooltip";

export type DailyDiaryGeneralInfoProps = {
  loading: boolean;
  contractTimezone: string;
  dateSent: string;
  sentBy: User;
};

const SectionDataHeader: React.FC<TypographyProps> = ({
  children,
  ...props
}) => (
  <Typography variant="p2" fontWeight={600} color="grey.800" {...props}>
    {children}
  </Typography>
);

const SectionData: React.FC<TypographyProps> = ({ children, ...props }) => (
  <Typography variant="p1" color="grey.600" {...props}>
    {children}
  </Typography>
);

export const DailyDiaryGeneralInfo: React.FC<DailyDiaryGeneralInfoProps> = ({
  loading,
  dateSent,
  contractTimezone,
  sentBy,
}) => {
  const { t } = useTranslation();

  return loading ? (
    <CenteredLoadingIndicator />
  ) : (
    <Grid container width="100%" spacing={3}>
      <Grid item xs={6} display="flex" flexDirection="column" flex={1}>
        <SectionDataHeader mb={0.5}>
          {t("common.labels.dateSent")}
        </SectionDataHeader>
        <DateWithTimeTooltip
          variant="p1"
          color="grey.600"
          datetime={dateSent}
          timezone={contractTimezone}
        />
      </Grid>
      <Grid item xs={6} display="flex" flexDirection="column" flex={1}>
        <SectionDataHeader mb={0.5}>
          {t("common.labels.sentBy")}
        </SectionDataHeader>
        <SectionData>{getUserName(sentBy)}</SectionData>
      </Grid>
    </Grid>
  );
};
