import { GridColDef, GridRowId } from "@mui/x-data-grid-pro";
import { useAttachmentsColumn } from "containers/Projects/containers/DailyDiary/components/AttachmentsColumn";
import { paddingColumnConfig } from "containers/Projects/containers/DailyDiary/DailyDiary.constants";
import {
  DailyDiaryPresetSection,
  DailyDiarySearchWeatherRecord,
} from "generated/graphql";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDiaryDateColumn } from "../../../hooks/useDiaryDateColumn";
import { useDiaryStatusColumn } from "../../../hooks/useDiaryStatusColumn";
import { unitValueFormatter } from "containers/Projects/containers/DailyDiary/components/WeatherSection/WeatherTable/WeatherTable.utils";
import { timeStripMinutes } from "containers/Projects/containers/DailyDiary/utilts";
import { useDiaryCreatorColumn } from "../../../hooks/useDiaryCreatorColumn";

export const useColumns = ({
  section,
  onAttachmentsClick,
}: {
  onAttachmentsClick: (rowId: GridRowId) => void;
  section: DailyDiaryPresetSection;
}) => {
  const { t } = useTranslation();
  const attachmentsColumn =
    useAttachmentsColumn<DailyDiarySearchWeatherRecord>(onAttachmentsClick);
  const diaryDateCol = useDiaryDateColumn();
  const diaryStatusCol = useDiaryStatusColumn();
  const creatorCol = useDiaryCreatorColumn();

  return useMemo((): GridColDef<DailyDiarySearchWeatherRecord>[] => {
    return [
      paddingColumnConfig,
      diaryStatusCol,
      diaryDateCol,
      {
        field: "conditions",
        headerName: t("Projects.DailyDiaries.Weather.conditions"),
        flex: 0.25,
        resizable: true,
        valueGetter: (_, row) => row.weatherConditions ?? "",
      },
      {
        field: "temperature",
        headerName: t("Projects.DailyDiaries.Weather.temperature"),
        maxWidth: 100,
        resizable: true,
        valueGetter: (_, row) => row.temperature,
        valueFormatter: (value?: string | null) =>
          unitValueFormatter(value, "Temperature", section, t),
      },
      {
        field: "rainFall",
        headerName: t("Projects.DailyDiaries.Weather.rainfall"),
        maxWidth: 90,
        resizable: true,
        valueGetter: (_, row) => row.rainFall,
        valueFormatter: (value?: string | null) =>
          unitValueFormatter(value, "Rainfall", section, t),
      },
      {
        field: "windSpeed",
        headerName: t("Projects.DailyDiaries.Weather.windSpeed"),
        maxWidth: 90,
        resizable: true,
        valueGetter: (_, row) => row.windSpeed,
        valueFormatter: (value?: string | null) =>
          unitValueFormatter(value, "WindSpeed", section, t),
      },
      {
        field: "humidity",
        headerName: t("Projects.DailyDiaries.Weather.humidity"),
        maxWidth: 70,
        resizable: true,
        valueGetter: (_, row) => row.humidity,
        valueFormatter: (value?: string | null) =>
          unitValueFormatter(value, "Humidity", section, t),
      },
      {
        field: "timeOfMeasurement",
        headerName: t("Projects.DailyDiaries.Weather.measuredAt"),
        maxWidth: 95,
        resizable: true,
        valueGetter: (_, row) => row.timeOfMeasurement,
        valueFormatter: (value: string) => timeStripMinutes(value),
      },
      creatorCol,
      attachmentsColumn,
    ];
  }, [t, section, attachmentsColumn, diaryDateCol, diaryStatusCol, creatorCol]);
};
