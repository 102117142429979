import {
  GridColDef,
  GridRenderCellParams,
  GridRowId,
} from "@mui/x-data-grid-pro";
import { useAttachmentsColumn } from "containers/Projects/containers/DailyDiary/components/AttachmentsColumn";
import { paddingColumnConfig } from "containers/Projects/containers/DailyDiary/DailyDiary.constants";
import { DailyDiarySearchHseRecord } from "generated/graphql";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDiaryDateColumn } from "../../../hooks/useDiaryDateColumn";
import { useDiaryStatusColumn } from "../../../hooks/useDiaryStatusColumn";
import { DescriptionColumnRenderer } from "containers/Projects/containers/DailyDiary/components/DescriptionColumnRenderer";
import { useDiaryCreatorColumn } from "../../../hooks/useDiaryCreatorColumn";

export const useColumns = ({
  onAttachmentsClick,
}: {
  onAttachmentsClick: (rowId: GridRowId) => void;
}) => {
  const { t } = useTranslation();
  const attachmentsCol =
    useAttachmentsColumn<DailyDiarySearchHseRecord>(onAttachmentsClick);
  const diaryDateCol = useDiaryDateColumn();
  const diaryStatusCol = useDiaryStatusColumn();
  const creatorCol = useDiaryCreatorColumn();

  return useMemo((): GridColDef<DailyDiarySearchHseRecord>[] => {
    return [
      paddingColumnConfig,
      diaryStatusCol,
      diaryDateCol,
      {
        field: "description",
        headerName: t("common.labels.description"),
        flex: 0.25,
        resizable: true,
        renderCell: (
          params: GridRenderCellParams<DailyDiarySearchHseRecord, any, any>
        ) => (
          <DescriptionColumnRenderer
            description={params.row.description ?? undefined}
          />
        ),
      },
      {
        field: "hseType",
        headerName: t("Projects.DailyDiaries.HSE.HSEType"),
        resizable: true,
        valueGetter: (_, row) => row.hseType ?? "",
      },
      creatorCol,
      attachmentsCol,
    ];
  }, [t, attachmentsCol, diaryDateCol, diaryStatusCol, creatorCol]);
};
