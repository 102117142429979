import {
  ProductInstanceUsersAccessQuery,
  ProductInstanceUsersAccessQueryVariables,
  ProductRoleStatus,
  ProductUserRoleStatus,
  User,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useMemo } from "react";
import { productInstanceUsersAccessQuery } from "./useUsersAccess.query";
import { getUserNameOrEmail } from "helpers/miscelaneous";

export const useUsersAccess = (productInstanceId: string) => {
  const { data: productInstanceData, loading: productInstanceDataLoading } =
    useGraphQuery<
      ProductInstanceUsersAccessQuery,
      ProductInstanceUsersAccessQueryVariables
    >(productInstanceUsersAccessQuery, {
      variables: { id: productInstanceId },
    });

  const usersRoles = useMemo(() => {
    const allProductInstanceProductRoles =
      productInstanceData?.productInstance.product.roles.items
        .filter((role) => role.status === ProductRoleStatus.Active)
        .map((role) => role.name);

    const result: Record<string, User[]> = {};
    allProductInstanceProductRoles?.forEach((roleName) => {
      result[roleName] = [];
    });

    productInstanceData?.productInstance.roles.items
      .filter((role) => role.status === ProductUserRoleStatus.Active)
      .sort((role1, role2) =>
        getUserNameOrEmail(role1.user as User)
          .toLowerCase()
          .localeCompare(getUserNameOrEmail(role2.user as User).toLowerCase())
      )
      .forEach((role) => {
        result[role.productRole.name].push(role.user as User);
      });

    return result;
  }, [productInstanceData]);

  return {
    usersRoles,
    usersAccessLoading: productInstanceDataLoading,
  };
};
