import { GridColDef } from "@mui/x-data-grid-pro";
import { DailyDiarySearchRecord } from "generated/graphql";
import { useTranslation } from "react-i18next";

export const useDiaryCreatorColumn = () => {
  const { t } = useTranslation();

  return {
    field: "creator",
    headerName: t("common.labels.createdBy"),
    flex: 0.1,
    resizable: true,
    sortable: false,
    valueGetter: (_, row) => row.creator,
  } as GridColDef<DailyDiarySearchRecord>;
};
