import {
  DraftInstructionItemsQuery,
  DraftInstructionItemsQueryVariables,
  InstructionItemsQuery,
  InstructionItemsQueryVariables,
} from "generated/graphql";
import { draftInstructionItemsQuery } from "graphql/queries/draftInstructionItems.query";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import { instructionItemsQuery } from "../Projects.query";

export const useInstructionsRegister = () => {
  const [
    fetchInstructionItemsData,
    { data: instructionItemsData, loading: instructionItemsDataLoading },
  ] = useGraphLazyQuery<InstructionItemsQuery, InstructionItemsQueryVariables>(
    instructionItemsQuery
  );

  const [
    fetchDraftInstructionItemsData,
    {
      data: draftInstructionItemsData,
      loading: draftInstructionItemsDataLoading,
    },
  ] = useGraphLazyQuery<
    DraftInstructionItemsQuery,
    DraftInstructionItemsQueryVariables
  >(draftInstructionItemsQuery);

  return {
    instructionItemsData,
    draftInstructionItemsData,
    instructionItemsDataLoading,
    draftInstructionItemsDataLoading,
    instructionsLoading:
      instructionItemsDataLoading || draftInstructionItemsDataLoading,
    fetchInstructionItemsData,
    fetchDraftInstructionItemsData,
  };
};
