import {
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridValueFormatter,
  GridValueGetter,
} from "@mui/x-data-grid-pro";
import { useAttachmentsColumn } from "containers/Projects/containers/DailyDiary/components/AttachmentsColumn";
import { paddingColumnConfig } from "containers/Projects/containers/DailyDiary/DailyDiary.constants";
import { DailyDiarySearchDelayRecord } from "generated/graphql";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDiaryDateColumn } from "../../../hooks/useDiaryDateColumn";
import { useDiaryStatusColumn } from "../../../hooks/useDiaryStatusColumn";
import { DescriptionColumnRenderer } from "containers/Projects/containers/DailyDiary/components/DescriptionColumnRenderer";
import { timeStripMinutes } from "containers/Projects/containers/DailyDiary/utilts";
import { useDiaryCreatorColumn } from "../../../hooks/useDiaryCreatorColumn";

export const useColumns = ({
  withAreaColumn,
  withShiftColumn,
  withDisciplineColumn,
  withTeamColumn,
  onAttachmentsClick,
}: {
  onAttachmentsClick: (rowId: GridRowId) => void;
  withAreaColumn?: boolean;
  withShiftColumn?: boolean;
  withDisciplineColumn?: boolean;
  withTeamColumn?: boolean;
}) => {
  const { t } = useTranslation();

  const attachmentsColumn =
    useAttachmentsColumn<DailyDiarySearchDelayRecord>(onAttachmentsClick);
  const diaryDateCol = useDiaryDateColumn();
  const diaryStatusCol = useDiaryStatusColumn();
  const creatorCol = useDiaryCreatorColumn();

  return useMemo((): GridColDef<DailyDiarySearchDelayRecord>[] => {
    return [
      paddingColumnConfig,
      diaryStatusCol,
      diaryDateCol,
      {
        field: "description",
        headerName: t("common.labels.description"),
        flex: 0.25,
        resizable: true,
        renderCell: (
          params: GridRenderCellParams<DailyDiarySearchDelayRecord, any, any>
        ) => (
          <DescriptionColumnRenderer
            description={params.row.description ?? undefined}
          />
        ),
      },
      {
        field: "delayType",
        headerName: t("Projects.DailyDiaries.Delay.delayType"),
        resizable: true,
        valueGetter: (_, row) => row.delayType ?? "",
      },
      ...(withShiftColumn
        ? [
            {
              field: "shift",
              headerName: t("Projects.DailyDiaries.Delay.shift"),
              resizable: true,
              valueGetter: ((_, row) => {
                return row.shift ?? "";
              }) as GridValueGetter<DailyDiarySearchDelayRecord>,
            },
          ]
        : []),
      ...(withAreaColumn
        ? [
            {
              field: "area",
              headerName: t("Projects.DailyDiaries.Delay.area"),
              resizable: true,
              valueGetter: ((_, row) => {
                return row.area ?? "";
              }) as GridValueGetter<DailyDiarySearchDelayRecord>,
            },
          ]
        : []),
      ...(withDisciplineColumn
        ? [
            {
              field: "discipline",
              headerName: t("Projects.DailyDiaries.Delay.discipline"),
              resizable: true,
              valueGetter: ((_, row) => {
                return row.discipline ?? "";
              }) as GridValueGetter<DailyDiarySearchDelayRecord>,
            },
          ]
        : []),
      ...(withTeamColumn
        ? [
            {
              field: "team",
              headerName: t("Projects.DailyDiaries.Delay.team"),
              resizable: true,
              valueGetter: ((_, row) =>
                row.team ??
                "-") as GridValueGetter<DailyDiarySearchDelayRecord>,
            },
          ]
        : []),
      {
        field: "duration",
        headerName: t("Projects.DailyDiaries.Delay.duration"),
        maxWidth: 70,
        resizable: true,
        valueGetter: (_, row) => row.duration,
        valueFormatter: ((value) =>
          timeStripMinutes(value)) as GridValueFormatter<
          DailyDiarySearchDelayRecord,
          any,
          any,
          string
        >,
      },
      creatorCol,
      attachmentsColumn,
    ];
  }, [
    t,
    withAreaColumn,
    withShiftColumn,
    withDisciplineColumn,
    withTeamColumn,
    attachmentsColumn,
    diaryDateCol,
    diaryStatusCol,
    creatorCol,
  ]);
};
