import {
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridValueGetter,
} from "@mui/x-data-grid-pro";
import {
  CompanyLookupCollection,
  DailyDiaryWorkRecord,
} from "generated/graphql";
import { useMemo } from "react";
import { useDailyDiaryStatus } from "../../../hooks/useDailyDiaryStatus";
import { DescriptionColumnRenderer } from "../../DescriptionColumnRenderer";
import { useTranslation } from "react-i18next";
import { useAttachmentsColumn } from "../../AttachmentsColumn";
import { useKebabButtonColumn } from "../../KebabButtonColumn";
import { useCreatedByColumn } from "../../CreatedByColumn";
import { paddingColumnConfig } from "../../../DailyDiary.constants";

export const useColumns = ({
  workLookups,
  withShiftColumn,
  withDescriptionColumn,
  withAreaColumn,
  withDisciplineColumn,
  withTeamColumn,
  withActivityColumn,
  withAttachments,
  onEdit,
  onRemove,
  onAttachmentsClick,
}: {
  onEdit?: (rowId: GridRowId) => void;
  onRemove?: (rowId: GridRowId) => void;
  onAttachmentsClick: (rowId: GridRowId) => void;
  withAttachments?: boolean;
  workLookups: CompanyLookupCollection[];
  withShiftColumn?: boolean;
  withDescriptionColumn?: boolean;
  withAreaColumn?: boolean;
  withDisciplineColumn?: boolean;
  withTeamColumn?: boolean;
  withActivityColumn?: boolean;
}) => {
  const { t } = useTranslation();
  const { isDraftOrEmptyDailyDiary } = useDailyDiaryStatus();
  const attachmentsCol =
    useAttachmentsColumn<DailyDiaryWorkRecord>(onAttachmentsClick);
  const kebabButtonColumn = useKebabButtonColumn<DailyDiaryWorkRecord>(
    onEdit,
    onRemove
  );
  const createdByColumn = useCreatedByColumn<DailyDiaryWorkRecord>();

  return useMemo((): GridColDef<DailyDiaryWorkRecord>[] => {
    return [
      paddingColumnConfig,
      ...(withDescriptionColumn
        ? [
            {
              field: "description",
              headerName: t("common.labels.description"),
              flex: 0.25,
              resizable: true,
              renderCell: (
                params: GridRenderCellParams<any, DailyDiaryWorkRecord, any>
              ) => (
                <DescriptionColumnRenderer
                  description={params.row.description ?? undefined}
                />
              ),
            },
          ]
        : []),
      ...(withShiftColumn
        ? [
            {
              field: "shift",
              headerName: t("Projects.DailyDiaries.Work.shift"),
              width: 95,
              resizable: true,
              valueGetter: ((_, row) => {
                return workLookups
                  .find((lkp) => lkp.id === row.shiftLookupId)
                  ?.options.items.find(
                    (option) => option.id === row.shiftOptionId
                  )?.value;
              }) as GridValueGetter<DailyDiaryWorkRecord>,
            },
          ]
        : []),
      ...(withAreaColumn
        ? [
            {
              field: "area",
              headerName: t("Projects.DailyDiaries.Work.area"),
              resizable: true,
              valueGetter: ((_, row) => {
                return workLookups
                  .find((lkp) => lkp.id === row.areaLookupId)
                  ?.options.items.find(
                    (option) => option.id === row.areaOptionId
                  )?.value;
              }) as GridValueGetter<DailyDiaryWorkRecord>,
            },
          ]
        : []),
      ...(withDisciplineColumn
        ? [
            {
              field: "discipline",
              headerName: t("Projects.DailyDiaries.Work.discipline"),
              resizable: true,
              valueGetter: ((_, row) => {
                return workLookups
                  .find((lkp) => lkp.id === row.disciplineLookupId)
                  ?.options.items.find(
                    (option) => option.id === row.disciplineOptionId
                  )?.value;
              }) as GridValueGetter<DailyDiaryWorkRecord>,
            },
          ]
        : []),
      ...(withTeamColumn
        ? [
            {
              field: "team",
              headerName: t("Projects.DailyDiaries.Work.team"),
              resizable: true,
              valueGetter: ((_, row) =>
                workLookups
                  .find((lkp) => lkp.id === row.teamLookupId)
                  ?.options.items.find(
                    (option) => option.id === row.teamOptionId
                  )?.value) as GridValueGetter<DailyDiaryWorkRecord>,
            },
          ]
        : []),
      ...(withActivityColumn
        ? [
            {
              field: "activity",
              headerName: t("Projects.DailyDiaries.Work.activity"),
              resizable: true,
              valueGetter: ((_, row) =>
                workLookups
                  .find((lkp) => lkp.id === row.activityLookupId)
                  ?.options.items.find(
                    (option) => option.id === row.activityOptionId
                  )?.value) as GridValueGetter<DailyDiaryWorkRecord>,
            },
          ]
        : []),
      {
        field: "workType",
        headerName: t("Projects.DailyDiaries.Work.workType"),
        resizable: true,
        valueGetter: (_, row) =>
          workLookups
            .find((lkp) => lkp.id === row.workTypeLookupId)
            ?.options.items.find((option) => option.id === row.workTypeOptionId)
            ?.value,
      },
      {
        field: "amount",
        headerName: t("Projects.DailyDiaries.Work.amount"),
        resizable: true,
      },
      {
        field: "unitOfMeasurement",
        headerName: t("Projects.DailyDiaries.Work.unitOfMeasurement"),
        resizable: true,
        maxWidth: 50,
        valueGetter: (_, row) =>
          workLookups
            .find((lkp) => lkp.id === row.unitOfMeasurementLookupId)
            ?.options.items.find(
              (option) => option.id === row.unitOfMeasurementOptionId
            )?.value,
      },
      createdByColumn,
      ...(withAttachments ? [attachmentsCol] : []),
      ...(isDraftOrEmptyDailyDiary && (onRemove || onEdit)
        ? [{ ...kebabButtonColumn, width: 54 }]
        : []),
    ];
  }, [
    t,
    workLookups,
    withShiftColumn,
    withDescriptionColumn,
    withAreaColumn,
    withDisciplineColumn,
    withTeamColumn,
    withActivityColumn,
    withAttachments,
    createdByColumn,
    kebabButtonColumn,
    isDraftOrEmptyDailyDiary,
    attachmentsCol,
    onEdit,
    onRemove,
  ]);
};
