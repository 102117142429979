import { DailyDiaryPresetSection } from "generated/graphql";
import { useMemo } from "react";

export const useResourceSectionCommon = (section: DailyDiaryPresetSection) => {
  const withShiftColumn = useMemo(() => {
    const shiftCol = section.fields.find((field) => field.name === "Shift");

    return !!shiftCol && !shiftCol?.isHidden;
  }, [section]);

  const withCompanyColumn = useMemo(() => {
    const companyCol = section.fields.find((field) => field.name === "Company");

    return !!companyCol && !companyCol?.isHidden;
  }, [section]);

  const withTeamColumn = useMemo(() => {
    const teamCol = section.fields.find((field) => field.name === "Team");

    return !!teamCol && !teamCol?.isHidden;
  }, [section]);

  return {
    withShiftColumn,
    withCompanyColumn,
    withTeamColumn,
  };
};
