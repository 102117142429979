import { dateISOFormat } from "constants/constants";
import { ProductType } from "generated/graphql";
import moment from "moment";
import { NewAppPaths } from "./paths";

export const projectDetailsPathConstructor = (projectId: string) => {
  const path =
    NewAppPaths.authorized.AdminConsole.children.Projects.children
      .ProjectDetails.path;

  return path.replace(":projectId", projectId);
};

export const projectNewContractPathConstructor = (projectId: string) => {
  const path =
    NewAppPaths.authorized.AdminConsole.children.Projects.children
      .ProjectDetails.children.NewContract.path;

  return path.replace(":projectId", projectId);
};

export const projectNewAuthorizationWorkflowPathConstructor = (
  projectId: string
) => {
  const path =
    NewAppPaths.authorized.AdminConsole.children.Projects.children
      .ProjectDetails.children.NewAuthorizationWorkflow.path;

  return path.replace(":projectId", projectId);
};

export const companyDetailsPathConstructor = (companyId: string) => {
  const path =
    NewAppPaths.authorized.AdminConsole.children.Companies.children
      .CompanyDetails.path;

  return path.replace(":companyId", companyId);
};

export const companyUserDetailsPathConstructor = (
  companyId: string,
  userId: string
) => {
  const path =
    NewAppPaths.authorized.AdminConsole.children.Companies.children
      .CompanyDetails.children.UserDetails.path;

  return path.replace(":companyId", companyId).replace(":userId", userId);
};

export const companyInviteUserPathConstructor = (companyId: string) => {
  const path =
    NewAppPaths.authorized.AdminConsole.children.Companies.children
      .CompanyDetails.children.InviteUser.path;

  return path.replace(":companyId", companyId);
};

export const contractDetailsPathConstructor = (
  projectId: string,
  contractId: string
) => {
  const path =
    NewAppPaths.authorized.AdminConsole.children.Projects.children
      .ContractDetails.path;
  return path
    .replace(":projectId", projectId)
    .replace(":contractId", contractId);
};

export const authWorkflowDetailsPathConstructor = (
  projectId: string,
  authorizationWofklowId: string
) => {
  const path =
    NewAppPaths.authorized.AdminConsole.children.Projects.children
      .AuthorizationWorkflowDetails.path;
  return path
    .replace(":projectId", projectId)
    .replace(":authWorkflowId", authorizationWofklowId);
};

export const authAuditTrailDetailsPathConstructor = (
  projectId: string,
  authAuditTrailId: string
) => {
  const path =
    NewAppPaths.authorized.AdminConsole.children.Projects.children
      .AuthorizationAuditTrailDetails.path;
  return path
    .replace(":projectId", projectId)
    .replace(":authAuditTrailId", authAuditTrailId);
};

export const contractNewBindingPathConstructor = (
  projectId: string,
  contractId: string
) => {
  const path =
    NewAppPaths.authorized.AdminConsole.children.Projects.children
      .ContractDetails.children.NewBinding.path;
  return path
    .replace(":projectId", projectId)
    .replace(":contractId", contractId);
};

export const contractBindingDetailsPathConstructor = (
  projectId: string,
  contractId: string,
  bindingId: string
) => {
  const path =
    NewAppPaths.authorized.AdminConsole.children.Projects.children
      .ContractDetails.children.BindingDetails.path;
  return path
    .replace(":projectId", projectId)
    .replace(":contractId", contractId)
    .replace(":bindingId", bindingId);
};

export const contractNewProductInstancePathConstructor = (
  projectId: string,
  contractId: string
) => {
  const path =
    NewAppPaths.authorized.AdminConsole.children.Projects.children
      .ContractDetails.children.NewProductInstance.path;
  return path
    .replace(":projectId", projectId)
    .replace(":contractId", contractId);
};

export const contractProductInstanceDetailsPathConstructor = (
  projectId: string,
  contractId: string,
  productInstanceId: string
) => {
  const path =
    NewAppPaths.authorized.AdminConsole.children.Projects.children
      .ContractDetails.children.ProductInstanceDetails.path;
  return path
    .replace(":projectId", projectId)
    .replace(":contractId", contractId)
    .replace(":productInstanceId", productInstanceId);
};

export const contractTypeDetailsPathConstructor = (contractTypeId: string) => {
  const path =
    NewAppPaths.authorized.AdminConsole.children.System.children
      .ContractTypeDetails.path;

  return path.replace(":contractTypeId", contractTypeId);
};

export const customLookupDetailsPathConstructor = (
  lookupCollectionId: string
) => {
  const path =
    NewAppPaths.authorized.AdminConsole.children.System.children
      .CustomLookupCollectionDetails.path;

  return path.replace(":lookupCollectionId", lookupCollectionId);
};

export const lookupDetailsPathConstructor = (lookupCollectionId: string) => {
  const path =
    NewAppPaths.authorized.AdminConsole.children.System.children
      .LookupCollectionDetails.path;

  return path.replace(":lookupCollectionId", lookupCollectionId);
};

export const effectPresetDetailsPathConstructor = (effectPresetId: string) => {
  const path =
    NewAppPaths.authorized.AdminConsole.children.System.children.LookupsEffect
      .children.EffectPreset.path;

  return path.replace(":effectPresetId", effectPresetId);
};

export const templateDetailsPathConstructor = (templateId: string) => {
  const path =
    NewAppPaths.authorized.AdminConsole.children.System.children.TemplateDetails
      .path;

  return path.replace(":templateId", templateId);
};

export const productDetailsPathConstructor = (productId: string) => {
  const path =
    NewAppPaths.authorized.AdminConsole.children.System.children.Products
      .children.ProductDetails.path;

  return path.replace(":productId", productId);
};

export const productSchemaDetailsPathConstructor = (
  productId: string,
  schemaId: string
) => {
  const path =
    NewAppPaths.authorized.AdminConsole.children.System.children.Products
      .children.ProductDetails.children.SchemaDetails.path;

  return path.replace(":productId", productId).replace(":schemaId", schemaId);
};

export const productStatusCollectionDetailsPathConstructor = (
  productId: string,
  collectionId: string
) => {
  const path =
    NewAppPaths.authorized.AdminConsole.children.System.children.Products
      .children.ProductDetails.children.StatusCollectionDetails.path;

  return path
    .replace(":productId", productId)
    .replace(":collectionId", collectionId);
};

export const productCollectionOptionDetailsPathConstructor = (
  productId: string,
  collectionId: string,
  optionId: string
) => {
  const path =
    NewAppPaths.authorized.AdminConsole.children.System.children.Products
      .children.ProductDetails.children.StatusCollectionDetails.children
      .CollectionOptionDetails.path;

  return path
    .replace(":productId", productId)
    .replace(":collectionId", collectionId)
    .replace(":optionId", optionId);
};

export const getNewProductItemPath = ({
  projectId,
  contractId,
  productId,
  productInstanceId,
  draftId,
  productType,
}: {
  projectId: string;
  contractId: string;
  productId: string;
  productInstanceId: string;
  productType: ProductType;
  draftId?: string;
}) => {
  const newProductItemPage =
    productType === ProductType.RisksRegister
      ? "new-risk"
      : productType === ProductType.EarlyWarnings
      ? "new-early-warning"
      : productType === ProductType.Events
      ? "new-event"
      : "new-instruction";
  let path = `${NewAppPaths.authorized.Projects.path}/${projectId}/contracts/${contractId}/products/${productId}/productInstances/${productInstanceId}/${newProductItemPage}`;

  if (draftId) {
    path += `?draftId=${draftId}`;
  }

  return path;
};

export const getProductItemDetailsPath = (
  productInstanceId: string,
  productItemId: string,
  productType: ProductType
) => {
  const productItemsName =
    productType === ProductType.RisksRegister
      ? "risks"
      : productType === ProductType.EarlyWarnings
      ? "early-warnings"
      : productType === ProductType.Events
      ? "events"
      : "instructions";

  return `${NewAppPaths.authorized.Projects.path}/product-instances/${productInstanceId}/${productItemsName}/${productItemId}`;
};

export const getEditRiskPath = (productInstanceId: string, riskId: string) => {
  return `${NewAppPaths.authorized.Projects.path}/product-instances/${productInstanceId}/risks/${riskId}/edit`;
};

export const getEditEventPath = (
  productInstanceId: string,
  eventId: string
) => {
  return `${NewAppPaths.authorized.Projects.path}/product-instances/${productInstanceId}/events/${eventId}/edit`;
};

export const getDailyDiaryDetailsPath = (
  productInstanceId: string,
  date: string,
  dailyDiaryId?: string,
  revisionId?: string
) => {
  const baseUrl = `${
    NewAppPaths.authorized.Projects.path
  }/product-instances/${productInstanceId}/daily-diaries/${moment(date).format(
    dateISOFormat
  )}`;

  let finalUrl = baseUrl;

  if (dailyDiaryId) {
    finalUrl += `?dailyDiaryId=${dailyDiaryId}`;

    if (revisionId) {
      finalUrl += `&revisionId=${revisionId}`;
    }
  }

  return finalUrl;
};

// export const getDailyDiariesSearchPath = (productInstanceId: string) =>
//   `${NewAppPaths.authorized.Projects.path}/product-instances/${productInstanceId}/daily-diaries/search`;

export const getAbsolutePath = (relativePath: string) => {
  return `${window.location.origin}${relativePath}`;
};
