import { gql } from "@apollo/client";
import { creatorFields } from "graphql/fragments/creator.fragment";
import { itemDataFields } from "graphql/fragments/itemData.fragment";

// TODO: See if it really needs to contain all the nested data. Especially for Register view
export const compEventItemsQuery = gql`
  ${creatorFields}
  ${itemDataFields}

  query compEventItems($productInstanceId: ID!) # $limit: Int!
  # $nextToken: String
  {
    compEventItems(productInstanceId: $productInstanceId) {
      items {
        __typename
        id
        number
        productInstanceId
        regardingId
        regardingType
        status
        price
        time
        earlyWarningId
        dateCreated
        dateModified
        creatorId
        deemingDate
        lastAction {
          actionType
          daysLate
          date
        }
        nextStep {
          actionType
          dueDate
        }
        creator {
          ...CreatorFields
        }
        productInstance {
          id
          description
          numberingFormat
          status
          product {
            id
            name
          }
          contract {
            id
            name
            friendlyName
            status
            timeZone
            valueCurrency
            project {
              id
              name
              friendlyName
              status
            }
          }
        }
        earlyWarning {
          id
          title
          dateSent
          number
          title
          productInstance {
            id
            product {
              id
            }
          }
        }
        regardingEvent {
          id
          title
          productInstanceId
          number
          data {
            ...ItemDataFields
          }
          # comments(limit: $limit, nextToken: $nextToken) { // TODO: uncomment when adding back commentsCount
          #   items {
          #     id
          #     replies {
          #       items {
          #         id
          #       }
          #     }
          #   }
          #   nextToken
          # }
          productInstance {
            id
            product {
              id
            }
          }
        }
        regardingInstruction {
          id
          title
          number
          productInstanceId
          data {
            ...ItemDataFields
          }
          # comments(limit: $limit, nextToken: $nextToken) { // TODO: uncomment when adding back commentsCount
          #   items {
          #     id
          #     replies {
          #       items {
          #         id
          #       }
          #     }
          #   }
          #   nextToken
          # }
          productInstance {
            id
            product {
              id
            }
          }
        }
        notifiedByParty {
          id
          description
          contractTypeId
          status
        }
      }
    }
  }
`;
