import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { StatusTagNew } from "components/StatusTag/StatusTagNew";
import { dailyDiaryStatusOptions } from "containers/Projects/components/DailyDiariesView/DailyDiariesView.constants";
import { DailyDiarySearchRecord } from "generated/graphql";
import { useTranslation } from "react-i18next";

export const useDiaryStatusColumn = () => {
  const { t } = useTranslation();

  return {
    field: "status",
    headerName: t("common.labels.status"),
    minWidth: 120,
    maxWidth: 130,
    resizable: true,
    sortable: false,
    valueGetter: (_, row) => row.diaryStatus,
    renderCell: (
      params: GridRenderCellParams<DailyDiarySearchRecord, any, any>
    ) => {
      return (
        <StatusTagNew
          selectedOptionId={params.value}
          disabled
          size="small"
          options={dailyDiaryStatusOptions}
        />
      );
    },
  } as GridColDef<DailyDiarySearchRecord>;
};
