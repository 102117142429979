import { Color, Typography, useTheme } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowId,
} from "@mui/x-data-grid-pro";
import { AttachmentsCellContainer } from "./AttachmentsCellContainer";
import { Paperclip } from "phosphor-react";
import { Attachment, AttachmentStatus, Maybe } from "generated/graphql";

export const useAttachmentsColumn = <
  T extends { attachments?: Maybe<Attachment[]>; attachmentCount?: number }
>(
  onAttachmentsClick: (rowId: GridRowId) => void
): GridColDef<T> => {
  const theme = useTheme();

  return {
    field: "attachments",
    maxWidth: 35,
    sortable: false,
    disableColumnMenu: true,
    resizable: false,
    headerName: "",
    valueGetter: (_, row) => {
      const nonDeletedFiles = ((row.attachments as Attachment[]) ?? []).filter(
        (attach) => attach.status === AttachmentStatus.Active
      );
      const attachmentsLength = nonDeletedFiles.length ?? 0;

      return row.attachmentCount ?? attachmentsLength;
    },
    renderCell: (params: GridRenderCellParams<T, number, any>) => {
      const attachmentsLength = params.value!;
      const color = (theme.palette.secondary as Partial<Color>)[700];

      return attachmentsLength > 0 ? (
        <AttachmentsCellContainer
          clickable={attachmentsLength > 0}
          onClick={() => attachmentsLength && onAttachmentsClick(params.id)}
        >
          <Paperclip size={16} color={color} />
          <Typography variant="p2" color={color} ml={0}>
            {attachmentsLength}
          </Typography>
        </AttachmentsCellContainer>
      ) : null;
    },
  };
};
