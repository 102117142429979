import { gql } from "@apollo/client";
import { creatorFields } from "graphql/fragments/creator.fragment";
import { itemStatusOptionFields } from "graphql/fragments/itemStatusOption.fragment";
import { itemDataFields } from "graphql/fragments/itemData.fragment";
import { eventItemFields } from "graphql/fragments/eventItem.fragment";
import { instructionItemFields } from "graphql/fragments/instructionItem.fragment";

export const riskItemsQuery = gql`
  ${itemDataFields}
  ${itemStatusOptionFields}
  ${creatorFields}

  query riskItems($productInstanceId: ID!) {
    riskItems(productInstanceId: $productInstanceId) {
      items {
        id
        title
        number
        productInstanceId
        productInstance {
          id
          contract {
            id
            valueCurrency
            timeZone
          }
        }
        ownerId
        statusOptionId
        severity
        data {
          ...ItemDataFields
        }
        dateCreated
        dateModified
        creatorId
        creator {
          ...CreatorFields
        }
        statusOption {
          ...ItemStatusOptionFields
        }
        owner {
          ...CreatorFields
        }
        # comments(limit: $limit, nextToken: $nextToken) { // TODO: uncomment when adding back commentsCount
        #   nextToken
        #   items {
        #     id
        #     replies {
        #       items {
        #         id
        #       }
        #     }
        #   }
        # }
      }
    }
  }
`;

export const getProductInstanceStatusCollectionQuery = gql`
  ${itemStatusOptionFields}

  query getProductInstanceStatusCollection($id: ID!) {
    productInstance(id: $id) {
      id
      description
      product {
        id
        name
      }
      statusCollection {
        id
        name
        status
        statusOptions {
          items {
            ...ItemStatusOptionFields
          }
        }
      }
    }
  }
`;

export const earlyWarningItemsQuery = gql`
  ${itemStatusOptionFields}
  ${creatorFields}
  ${itemDataFields}

  query earlyWarningItems($productInstanceId: ID!) # $limit: Int!
  # $nextToken: String
  {
    earlyWarningItems(productInstanceId: $productInstanceId) {
      items {
        id
        title
        severity
        data {
          ...ItemDataFields
        }
        number
        productInstanceId
        ownerId
        statusOptionId
        dateCreated
        creatorId
        # TODO: why creator necessary on the Register view?
        creator {
          ...CreatorFields
        }
        statusOption {
          ...ItemStatusOptionFields
        }
        owner {
          ...CreatorFields
        }
        # TODO: why currency necessary on the Register view?
        productInstance {
          id
          contract {
            id
            valueCurrency
            timeZone
          }
        }
        dateModified
        offline
        givenById
        dateSent
        # comments(limit: $limit, nextToken: $nextToken) { // TODO: uncomment when adding back commentsCount
        #   nextToken
        #   items {
        #     id
        #     replies {
        #       items {
        #         id
        #       }
        #     }
        #   }
        # }
      }
    }
  }
`;

export const draftRiskItemsQuery = gql`
  ${itemStatusOptionFields}
  ${creatorFields}
  ${itemDataFields}

  query draftRiskItems($productInstanceId: ID!) {
    draftRiskItems(productInstanceId: $productInstanceId) {
      items {
        id
        title
        number
        productInstanceId
        productInstance {
          id
          contract {
            id
            timeZone
          }
        }
        ownerId
        statusOptionId
        severity
        data {
          ...ItemDataFields
        }
        dateCreated
        dateModified
        creatorId
        creator {
          ...CreatorFields
        }
        statusOption {
          ...ItemStatusOptionFields
        }
        owner {
          ...CreatorFields
        }
      }
    }
  }
`;

export const draftEarlyWarningItemsQuery = gql`
  ${itemStatusOptionFields}
  ${creatorFields}

  query draftEarlyWarningItems($productInstanceId: ID!) {
    draftEarlyWarningItems(productInstanceId: $productInstanceId) {
      items {
        id
        title
        severity
        data {
          sections {
            name
            entries {
              name
              value
            }
          }
        }
        number
        productInstanceId
        ownerId
        statusOptionId
        dateCreated
        creatorId
        creator {
          ...CreatorFields
        }
        statusOption {
          ...ItemStatusOptionFields
        }
        owner {
          ...CreatorFields
        }
        productInstance {
          contract {
            valueCurrency
            timeZone
          }
        }
        dateModified
        offline
        givenById
      }
    }
  }
`;

export const eventItemsQuery = gql`
  ${eventItemFields}

  query eventItems($productInstanceId: ID!) {
    eventItems(productInstanceId: $productInstanceId) {
      items {
        ...EventItemFields
        compEvent {
          id
          number
        }
        claim {
          id
          number
        }
        # TODO add variation when added
        # comments(limit: $limit, nextToken: $nextToken) { // TODO: uncomment when adding back commentsCount
        #   nextToken
        #   items {
        #     id
        #     replies {
        #       items {
        #         id
        #       }
        #     }
        #   }
        # }
      }
    }
  }
`;

export const instructionItemsQuery = gql`
  ${instructionItemFields}

  query instructionItems($productInstanceId: ID!) # $limit: Int!
  # $nextToken: String
  {
    instructionItems(productInstanceId: $productInstanceId) {
      items {
        ...InstructionItemFields
        compEvent {
          id
          number
        }
        claim {
          id
          number
        }
        variation {
          id
          number
        }
        # comments(limit: $limit, nextToken: $nextToken) { // TODO: uncomment when adding back commentsCount
        #   nextToken
        #   items {
        #     id
        #     replies {
        #       items {
        #         id
        #       }
        #     }
        #   }
        # }
      }
    }
  }
`;
