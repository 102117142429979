import {
  AttachmentDownloadPreSignedUrlQuery,
  AttachmentDownloadPreSignedUrlQueryVariables,
} from "generated/graphql";
import { attachmentDownloadPreSignedUrlQuery } from "graphql/queries/attachmentDownloadPreSignedUrl.query";
import { useGraphLazyQueryLite } from "hooks/useGraphLazyQueryLite";
import { useCallback } from "react";

export const useAttachmentDownloadPresignedUrl = () => {
  const [fetchAttachmentDownloadPresignedUrl] = useGraphLazyQueryLite<
    AttachmentDownloadPreSignedUrlQuery,
    AttachmentDownloadPreSignedUrlQueryVariables
  >(attachmentDownloadPreSignedUrlQuery);

  const doGetAttachmentDownloadPresignedUrl = useCallback(
    async (fileUrl: string, fileName?: string) => {
      const response = await fetchAttachmentDownloadPresignedUrl({
        fileUrl,
        fileName,
      });

      return response.data.attachmentDownloadPreSignedUrl;
    },
    [fetchAttachmentDownloadPresignedUrl]
  );

  return {
    getAttachmentDownloadPresignedUrl: doGetAttachmentDownloadPresignedUrl,
  };
};
