import { IconButton, IconButtonProps } from "@mui/material";
import { useNavigateBack } from "hooks/useNavigateBack";
import { CaretLeft } from "phosphor-react";

export type HeaderBackButtonProps = {
  disabled?: boolean;
  onBack?: () => void;
  iconColor?: string;
} & IconButtonProps;

export const HeaderBackButton: React.FC<HeaderBackButtonProps> = ({
  disabled = false,
  iconColor,
  onBack,
  ...restProps
}) => {
  const navigateBack = useNavigateBack();

  return (
    <IconButton
      onClick={onBack ?? navigateBack}
      data-testid="back-btn"
      size="medium"
      disabled={disabled}
      sx={{ p: 0.75 }}
      {...restProps}
    >
      <CaretLeft color={iconColor} />
    </IconButton>
  );
};
