import { Stack, Typography, useTheme } from "@mui/material";
import { BasicModal, BasicModalProps } from "components/BasicModal/BasicModal";
import { IconContainer } from "components/IconContainer";
import { User } from "generated/graphql";
import { UsersFour } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { RoleSection, UserRole } from "./RoleSection";

export type UsersAccessModalProps = {
  usersRoles: Record<string, User[]>;
} & BasicModalProps;

export const UsersAccessModal: React.FC<UsersAccessModalProps> = ({
  usersRoles,
  ...modalProps
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <BasicModal
      title={
        <Stack spacing={1}>
          <Typography
            variant="h3"
            fontWeight={600}
            fontSize="20px"
            color="grey.800"
          >
            {t("Projects.UsersAccessModal.title", {
              count: Object.values(usersRoles).flat().length,
            })}
          </Typography>
          <Typography variant="p1" color="grey.700">
            {t("Projects.UsersAccessModal.subtitle")}
          </Typography>
        </Stack>
      }
      maxWidth="sm"
      headerIcon={
        <IconContainer greyBackground>
          <UsersFour size={24} color={theme.palette.grey[800]} />
        </IconContainer>
      }
      {...modalProps}
    >
      <Stack spacing={1} mt={-1}>
        {Object.values(UserRole).map((roleName) => (
          <RoleSection
            key={roleName}
            roleName={roleName}
            users={usersRoles[roleName] ?? []}
          />
        ))}
      </Stack>
    </BasicModal>
  );
};
