import {
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridValueGetter,
} from "@mui/x-data-grid-pro";
import { DailyDiarySearchWorkRecord } from "generated/graphql";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DescriptionColumnRenderer } from "containers/Projects/containers/DailyDiary/components/DescriptionColumnRenderer";
import { useAttachmentsColumn } from "containers/Projects/containers/DailyDiary/components/AttachmentsColumn";
import { paddingColumnConfig } from "containers/Projects/containers/DailyDiary/DailyDiary.constants";
import { useDiaryDateColumn } from "../../../hooks/useDiaryDateColumn";
import { useDiaryStatusColumn } from "../../../hooks/useDiaryStatusColumn";
import { useDiaryCreatorColumn } from "../../../hooks/useDiaryCreatorColumn";

export const useColumns = ({
  withShiftColumn,
  withDescriptionColumn,
  withAreaColumn,
  withDisciplineColumn,
  withTeamColumn,
  withActivityColumn,
  onAttachmentsClick,
}: {
  onAttachmentsClick: (rowId: GridRowId) => void;
  withShiftColumn?: boolean;
  withDescriptionColumn?: boolean;
  withAreaColumn?: boolean;
  withDisciplineColumn?: boolean;
  withTeamColumn?: boolean;
  withActivityColumn?: boolean;
}) => {
  const { t } = useTranslation();

  const attachmentsCol =
    useAttachmentsColumn<DailyDiarySearchWorkRecord>(onAttachmentsClick);
  const diaryDateCol = useDiaryDateColumn();
  const diaryStatusCol = useDiaryStatusColumn();
  const creatorCol = useDiaryCreatorColumn();

  return useMemo((): GridColDef<DailyDiarySearchWorkRecord>[] => {
    return [
      paddingColumnConfig,
      diaryStatusCol,
      diaryDateCol,
      ...(withDescriptionColumn
        ? [
            {
              field: "description",
              headerName: t("common.labels.description"),
              flex: 0.25,
              resizable: true,
              renderCell: (
                params: GridRenderCellParams<
                  DailyDiarySearchWorkRecord,
                  any,
                  any
                >
              ) => (
                <DescriptionColumnRenderer
                  description={params.row.description ?? ""}
                />
              ),
            },
          ]
        : []),
      ...(withShiftColumn
        ? [
            {
              field: "shift",
              headerName: t("Projects.DailyDiaries.Work.shift"),
              width: 95,
              resizable: true,
              valueGetter: ((_, row) => {
                return row.shift ?? "";
              }) as GridValueGetter<DailyDiarySearchWorkRecord>,
            },
          ]
        : []),
      ...(withAreaColumn
        ? [
            {
              field: "area",
              headerName: t("Projects.DailyDiaries.Work.area"),
              resizable: true,
              valueGetter: ((_, row) => {
                return row.area ?? "";
              }) as GridValueGetter<DailyDiarySearchWorkRecord>,
            },
          ]
        : []),
      ...(withDisciplineColumn
        ? [
            {
              field: "discipline",
              headerName: t("Projects.DailyDiaries.Work.discipline"),
              resizable: true,
              valueGetter: ((_, row) => {
                return row.discipline ?? "";
              }) as GridValueGetter<DailyDiarySearchWorkRecord>,
            },
          ]
        : []),
      ...(withTeamColumn
        ? [
            {
              field: "team",
              headerName: t("Projects.DailyDiaries.Work.team"),
              resizable: true,
              valueGetter: ((_, row) => {
                return row.team ?? "";
              }) as GridValueGetter<DailyDiarySearchWorkRecord>,
            },
          ]
        : []),
      ...(withActivityColumn
        ? [
            {
              field: "activity",
              headerName: t("Projects.DailyDiaries.Work.activity"),
              resizable: true,
              valueGetter: ((_, row) =>
                row.activity ??
                "") as GridValueGetter<DailyDiarySearchWorkRecord>,
            },
          ]
        : []),
      {
        field: "workType",
        headerName: t("Projects.DailyDiaries.Work.workType"),
        resizable: true,
        valueGetter: (_, row) => row.workType ?? "",
      },
      {
        field: "amount",
        headerName: t("Projects.DailyDiaries.Work.amount"),
        resizable: true,
      },
      {
        field: "unitOfMeasurement",
        headerName: t("Projects.DailyDiaries.Work.unitOfMeasurement"),
        resizable: true,
        maxWidth: 50,
        valueGetter: (_, row) => row.unitOfMeasurement ?? "",
      },
      creatorCol,
      attachmentsCol,
    ];
  }, [
    t,
    withShiftColumn,
    withDescriptionColumn,
    withAreaColumn,
    withDisciplineColumn,
    withTeamColumn,
    withActivityColumn,
    attachmentsCol,
    diaryDateCol,
    diaryStatusCol,
    creatorCol,
  ]);
};
