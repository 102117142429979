import {
  GridColDef,
  GridRenderCellParams,
  GridRowId,
} from "@mui/x-data-grid-pro";
import { DailyDiaryGeneralRecord } from "generated/graphql";
import { useMemo } from "react";
import { useDailyDiaryStatus } from "../../../hooks/useDailyDiaryStatus";
import { DescriptionColumnRenderer } from "../../DescriptionColumnRenderer";
import { useTranslation } from "react-i18next";
import { useAttachmentsColumn } from "../../AttachmentsColumn";
import { useKebabButtonColumn } from "../../KebabButtonColumn";
import { useCreatedByColumn } from "../../CreatedByColumn";
import { paddingColumnConfig } from "../../../DailyDiary.constants";

export const useColumns = ({
  withAttachments,
  onEdit,
  onRemove,
  onAttachmentsClick,
}: {
  withAttachments?: boolean;
  onEdit?: (rowId: GridRowId) => void;
  onRemove?: (rowId: GridRowId) => void;
  onAttachmentsClick: (rowId: GridRowId) => void;
}) => {
  const { t } = useTranslation();
  const { isDraftOrEmptyDailyDiary } = useDailyDiaryStatus();
  const attachmentsCol =
    useAttachmentsColumn<DailyDiaryGeneralRecord>(onAttachmentsClick);
  const kebabButtonColumn = useKebabButtonColumn<DailyDiaryGeneralRecord>(
    onEdit,
    onRemove
  );
  const createdByColumn = useCreatedByColumn<DailyDiaryGeneralRecord>();

  return useMemo((): GridColDef<DailyDiaryGeneralRecord>[] => {
    return [
      paddingColumnConfig,
      {
        field: "description",
        headerName: t("common.labels.description"),
        flex: 0.25,
        maxWidth: 450,
        resizable: true,
        renderCell: (
          params: GridRenderCellParams<any, DailyDiaryGeneralRecord, any>
        ) => <DescriptionColumnRenderer description={params.row.description} />,
      },
      createdByColumn,
      ...(withAttachments ? [attachmentsCol] : []),
      ...(isDraftOrEmptyDailyDiary && (onRemove || onEdit)
        ? [{ ...kebabButtonColumn, width: 54 }]
        : []),
    ];
  }, [
    t,
    isDraftOrEmptyDailyDiary,
    withAttachments,
    kebabButtonColumn,
    createdByColumn,
    attachmentsCol,
    onEdit,
    onRemove,
  ]);
};
