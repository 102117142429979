import { Stack, TextField, Typography } from "@mui/material";
import { ChangeEventHandler } from "react";
import { useTranslation } from "react-i18next";

type KeywordSearchProps = {
  keyword?: string;
  onKeywordChange: (newKeyword: string) => void;
  onEnter: () => void;
};

export const KeywordSearch: React.FC<KeywordSearchProps> = ({
  keyword,
  onKeywordChange,
  onEnter,
}) => {
  const { t } = useTranslation();

  const handleTextFieldChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (evt) => onKeywordChange(evt.target.value);

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (evt) => {
    if (evt.key === "Enter") {
      onEnter();
    }
  };

  return (
    <Stack direction="column" spacing={1} px={3}>
      <Typography variant="p2" fontWeight={600} color="grey.800">
        {t("Projects.DailyDiariesSearch.keyword")}
      </Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          fullWidth
          name="quantity"
          value={keyword}
          onKeyDown={handleKeyDown}
          onChange={handleTextFieldChange}
          variant="outlined"
          placeholder={t("Projects.DailyDiariesSearch.keywordPlaceholder")}
          size="small"
        />
      </Stack>
    </Stack>
  );
};
