import {
  DailyDiaryItem,
  DailyDiaryManpowerRecordsQuery,
  DailyDiaryManpowerRecordsQueryVariables,
  DailyDiaryResourceRecord,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import { dailyDiaryManpowerRecordsQuery } from "../ManpowerSection.query";

export const useManpowerRecords = (
  dailyDiary?: DailyDiaryItem, // TODO: pass only dailyDiaryId - no need for the entire object
  revisionId?: string
) => {
  const {
    data: manpowerRecords,
    loading: manpowerRecordsLoading,
    refetch: refetchDDManpowerRecords,
  } = useGraphQuery<
    DailyDiaryManpowerRecordsQuery,
    DailyDiaryManpowerRecordsQueryVariables
  >(dailyDiaryManpowerRecordsQuery, {
    variables: { dailyDiaryId: dailyDiary?.id!, revisionId },
    skip: !dailyDiary,
    notifyOnNetworkStatusChange: true,
  });

  return {
    manpowerRecords: (manpowerRecords?.dailyDiaryManpowerRecords ??
      []) as DailyDiaryResourceRecord[],
    manpowerRecordsLoading,
    refetchDDManpowerRecords,
  };
};
