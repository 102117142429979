import {
  AddDailyDiaryDelayRecordMutation,
  AddDailyDiaryDelayRecordMutationVariables,
  DailyDiaryItem,
  EditDailyDiaryDelayRecordMutation,
  EditDailyDiaryDelayRecordMutationVariables,
  RemoveDailyDiaryDelayRecordMutation,
  RemoveDailyDiaryDelayRecordMutationVariables,
} from "generated/graphql";
import { addDailyDiaryDelayRecordMutation } from "graphql/mutations/addDailyDiaryDelayRecord";
import { editDailyDiaryDelayRecordMutation } from "graphql/mutations/editDailyDiaryDelayRecord";
import { removeDailyDiaryDelayRecordMutation } from "graphql/mutations/removeDailyDiaryDelayRecord";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DailyDiaryContext } from "../../../DailyDiaryContextProvider";
import { useDelayRecords } from "./useDelayRecords";

export const useDelaySection = (
  dailyDiary?: DailyDiaryItem,
  revisionId?: string
) => {
  const { t } = useTranslation();

  const { refetchDDByDate } = useContext(DailyDiaryContext);

  const { delayRecords, delayRecordsLoading, refetchDDDelayRecords } =
    useDelayRecords(dailyDiary, revisionId);

  const [addDDDelayRecord, { loading: addDDDelayRecordLoading }] =
    useGraphMutation<
      AddDailyDiaryDelayRecordMutation,
      AddDailyDiaryDelayRecordMutationVariables
    >(
      addDailyDiaryDelayRecordMutation,
      {
        update: async () => {
          if (!dailyDiary) {
            await refetchDDByDate?.();
          } else {
            refetchDDDelayRecords();
          }
        },
      },
      t("common.successMessages.entityCreated", {
        entity: t("Projects.DailyDiaries.delayRecord"),
      })
    );

  const [editDDDelayRecord, { loading: editDDDelayRecordLoading }] =
    useGraphMutation<
      EditDailyDiaryDelayRecordMutation,
      EditDailyDiaryDelayRecordMutationVariables
    >(
      editDailyDiaryDelayRecordMutation,
      {
        update: () => {
          refetchDDDelayRecords();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("Projects.DailyDiaries.delayRecord"),
      })
    );

  const [removeDDDelayRecord, { loading: removeDDDelayRecordLoading }] =
    useGraphMutation<
      RemoveDailyDiaryDelayRecordMutation,
      RemoveDailyDiaryDelayRecordMutationVariables
    >(
      removeDailyDiaryDelayRecordMutation,
      {
        update: () => {
          refetchDDDelayRecords();
        },
      },
      t("common.successMessages.entityDeleted", {
        entity: t("Projects.DailyDiaries.delayRecord"),
      })
    );

  return {
    delayRecords,
    loading:
      delayRecordsLoading ||
      addDDDelayRecordLoading ||
      editDDDelayRecordLoading ||
      removeDDDelayRecordLoading,
    refetchDDDelayRecords,
    addDelayRecord: addDDDelayRecord,
    editDelayRecord: editDDDelayRecord,
    removeDelayRecord: removeDDDelayRecord,
  };
};
