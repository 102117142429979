import { dailyDiarySearchRecordsMetadataQuery } from "../DailyDiariesSearch.query";
import {
  DailyDiarySearchRecordsMetadataQuery,
  DailyDiarySearchRecordsMetadataQueryVariables,
} from "generated/graphql";
import { useGraphLazyQueryLite } from "hooks/useGraphLazyQueryLite";

export const useDailyDiariesSearchMetadata = () => {
  const [searchRecordsMetadata] = useGraphLazyQueryLite<
    DailyDiarySearchRecordsMetadataQuery,
    DailyDiarySearchRecordsMetadataQueryVariables
  >(dailyDiarySearchRecordsMetadataQuery);

  return {
    searchRecordsMetadata,
  };
};
