import { LoadingButton } from "@mui/lab";
import { Box, Button, Tooltip, Typography, useTheme } from "@mui/material";
import { CloudCheckIcon } from "components/Icons/CloudCheck";
import { TrashIcon } from "components/Icons/TrashIcon";
import { SplitButton } from "components/SplitButton";
import { ProductItemHeaderContainer } from "containers/Projects/components/HeaderContainer";
import { EWInstructionAction } from "containers/Projects/Projects.decl";
import { ProductType } from "generated/graphql";
import { PaperPlaneTilt, Plugs } from "phosphor-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
// import { EditDraftProductItemContextMenu } from "../EditDraftProductItemContextMenu";

export type NewProductItemHeaderProps = {
  recordSupported?: boolean;
  disabled?: boolean;
  saveBtnDisabledTooltip?: string;
  upsertDraftProductItemLoading?: boolean;
  editMode?: boolean;
  saved?: boolean;
  productType: ProductType;
  onDuplicate?: () => void;
  onMoveToProject?: () => void;
  onDiscardDraft?: () => void;
  onSend?: () => void;
  onRecord?: () => void;
  onSaveDraft: () => void;
  onCancel: () => void;
  onBack: () => void;
};

export const NewProductItemHeader: React.FC<NewProductItemHeaderProps> = ({
  disabled,
  saveBtnDisabledTooltip,
  upsertDraftProductItemLoading,
  editMode = true,
  saved = true,
  recordSupported = true,
  productType,
  // onDuplicate,
  // onMoveToProject,
  onDiscardDraft,
  onSend,
  onRecord,
  onSaveDraft,
  onCancel,
  onBack,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleEWInstructionActionClick = (clickedOption: string) => {
    if ((clickedOption as EWInstructionAction) === EWInstructionAction.Send) {
      onSend?.();
    } else {
      onRecord?.();
    }
  };

  const title = useMemo(() => {
    switch (productType) {
      case ProductType.RisksRegister:
        return t(`Projects.Risks.${editMode ? "editDraftRisk" : "newRisk"}`);
      case ProductType.EarlyWarnings:
        return t(
          `Projects.EarlyWarnings.${
            editMode ? "editDraftEW" : "newEarlyWarning"
          }`
        );
      case ProductType.Events:
        return t(`Projects.Events.${editMode ? "editDraftEvent" : "newEvent"}`);
      case ProductType.Instructions:
        return t(
          `Projects.Instructions.${
            editMode ? "editDraftInstruction" : "newInstruction"
          }`
        );
    }
  }, [t, productType, editMode]);

  return (
    <ProductItemHeaderContainer height="72px">
      {(isHeaderStuck?: boolean) => (
        <>
          <Typography
            variant="h3"
            color={theme.palette.grey[900]}
            data-testid="edit-draft-title"
          >
            {title}
          </Typography>
          {editMode && (
            // <EditDraftProductItemContextMenu
            //   onDiscardDraft={onDiscardDraft}
            //   onDuplicate={onDuplicate}
            //   onMoveToProject={onMoveToProject}
            // />
            // TODO uncomment above and delete below btn when implementing the other options
            <Box mx={2}>
              <Button
                onClick={onDiscardDraft}
                variant="outlined"
                sx={{ borderColor: `${theme.palette.error.main} !important` }}
              >
                <TrashIcon color={theme.palette.error.main} />
                <Typography variant="p2" ml={1} color="error.main">
                  {t("common.labels.discard")}
                </Typography>
              </Button>
            </Box>
          )}
          {saved && (
            <Box display="flex" alignItems="center" ml={1}>
              <CloudCheckIcon />
              <Typography variant="p2" ml={1}>
                {t("common.labels.saved")}
              </Typography>
            </Box>
          )}
          <Box
            display="flex"
            alignItems="center"
            ml="auto"
            mr={isHeaderStuck ? 3 : 0}
          >
            {editMode ? (
              <Button
                variant="text"
                onClick={onBack}
                data-testid="header-back-btn"
              >
                {t("common.buttons.back")}
              </Button>
            ) : (
              <Button
                variant="text"
                onClick={onCancel}
                data-testid="header-cancel-btn"
              >
                {t("common.buttons.cancel")}
              </Button>
            )}
            <Box ml={1}>
              <LoadingButton
                variant="outlined"
                size="large"
                onClick={onSaveDraft}
                data-testid="header-save-changes-or-draft-btn"
                loading={upsertDraftProductItemLoading}
              >
                {editMode
                  ? t("common.labels.saveChanges")
                  : t("Projects.Risks.saveDraft")}
              </LoadingButton>
            </Box>
            <Box ml={1}>
              <Tooltip title={saveBtnDisabledTooltip ?? ""}>
                <Box>
                  {[ProductType.RisksRegister, ProductType.Events].indexOf(
                    productType
                  ) >= 0 || !recordSupported ? (
                    <LoadingButton
                      variant="contained"
                      size="large"
                      onClick={onSend}
                      disabled={disabled || upsertDraftProductItemLoading}
                      data-testid={
                        [ProductType.RisksRegister, ProductType.Events].indexOf(
                          productType
                        ) < 0
                          ? "header-send-btn"
                          : "header-create-btn"
                      }
                    >
                      <Typography variant="p2" fontWeight={700}>
                        {t(
                          `common.buttons.${
                            [
                              ProductType.RisksRegister,
                              ProductType.Events,
                            ].indexOf(productType) < 0
                              ? "send"
                              : "create"
                          }`
                        )}
                      </Typography>
                    </LoadingButton>
                  ) : (
                    <SplitButton
                      options={[
                        {
                          label: EWInstructionAction.Send,
                          icon: <PaperPlaneTilt size={18} />,
                        },
                        {
                          label: EWInstructionAction.Record,
                          icon: <Plugs size={18} />,
                        },
                      ]}
                      minWidth="100px !important"
                      disabled={disabled || upsertDraftProductItemLoading}
                      onClick={handleEWInstructionActionClick}
                    />
                  )}
                </Box>
              </Tooltip>
            </Box>
          </Box>
        </>
      )}
    </ProductItemHeaderContainer>
  );
};
