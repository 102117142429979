import { Stack, Typography } from "@mui/material";
import { ReactComponent as ListMagnifyingGlass } from "../../../../../assets/icons/list-magnifying-glass.svg";
import { useTranslation } from "react-i18next";

export const EmptyState: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack
      alignItems="center"
      width="100%"
      height="100%"
      justifyContent="center"
    >
      <ListMagnifyingGlass />
      <Typography mt={3} variant="p1" color="grey.800" fontWeight={600}>
        {t("Projects.DailyDiariesSearch.emtpyViewTitle")}
      </Typography>
      <Typography mt={1} variant="p2" color="grey.800">
        {t("Projects.DailyDiariesSearch.emtpyViewSubtitle")}
      </Typography>
    </Stack>
  );
};
