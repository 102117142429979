import { css, styled, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { DailyDiarySearchRecord } from "generated/graphql";
import { NewAppPaths } from "helpers/paths/paths";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";

export const StyledLink = styled(Link)(
  ({ theme }) => css`
    text-decoration: underline;
    cursor: pointer;
    color: ${theme.palette.grey[800]};

    &:hover {
      color: ${theme.palette.text.primary};
    }
  `
);

export const useDiaryDateColumn = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const productInstanceId = searchParams.get("productInstanceId");

  return {
    field: "date",
    headerName: t("common.labels.date"),
    minWidth: 120,
    maxWidth: 130,
    resizable: true,
    sortable: false,
    valueGetter: (_, row) => row.diaryDate,
    renderCell: (
      params: GridRenderCellParams<DailyDiarySearchRecord, any, any>
    ) => {
      return (
        <StyledLink
          to={NewAppPaths.authorized.Projects.children.DailyDiaryDetails.pathConstructor(
            productInstanceId!,
            params.value,
            params.row.diaryId
          )}
        >
          <Typography variant="p2">{params.value}</Typography>
        </StyledLink>
      );
    },
  } as GridColDef<DailyDiarySearchRecord>;
};
