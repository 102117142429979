import {
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridValueFormatter,
  GridValueGetter,
} from "@mui/x-data-grid-pro";
import { useAttachmentsColumn } from "containers/Projects/containers/DailyDiary/components/AttachmentsColumn";
import { paddingColumnConfig } from "containers/Projects/containers/DailyDiary/DailyDiary.constants";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDiaryDateColumn } from "../../../hooks/useDiaryDateColumn";
import { useDiaryStatusColumn } from "../../../hooks/useDiaryStatusColumn";
import { DescriptionColumnRenderer } from "containers/Projects/containers/DailyDiary/components/DescriptionColumnRenderer";
import { timeStripMinutes } from "containers/Projects/containers/DailyDiary/utilts";
import { datetimeToHoursAndMinutes } from "containers/Projects/containers/DailyDiary/components/ManpowerSection/ResourceRecordModal/ResourceRecordForm.utils";
import { DailyDiarySearchResourceRecord } from "./ResourceSectionSearchResults";
import { useDiaryCreatorColumn } from "../../../hooks/useDiaryCreatorColumn";

export const useColumns = ({
  withShiftColumn,
  withCompanyColumn,
  withTeamColumn,
  onAttachmentsClick,
}: {
  onAttachmentsClick: (rowId: GridRowId) => void;
  withShiftColumn?: boolean;
  withCompanyColumn?: boolean;
  withTeamColumn?: boolean;
}) => {
  const { t } = useTranslation();

  const attachmentsCol =
    useAttachmentsColumn<DailyDiarySearchResourceRecord>(onAttachmentsClick);
  const diaryDateCol = useDiaryDateColumn();
  const diaryStatusCol = useDiaryStatusColumn();
  const creatorCol = useDiaryCreatorColumn();

  return useMemo((): GridColDef<DailyDiarySearchResourceRecord>[] => {
    return [
      paddingColumnConfig,
      diaryStatusCol,
      diaryDateCol,
      {
        field: "resource",
        headerName: t("Projects.DailyDiaries.Resource.resource"),
        flex: 0.125,
        resizable: true,
        valueGetter: (_, row) => {
          return row.resource ?? "";
        },
      },
      ...(withShiftColumn
        ? [
            {
              field: "shift",
              headerName: t("Projects.DailyDiaries.Resource.shift"),
              width: 95,
              resizable: true,
              valueGetter: ((_, row) => {
                return row.shift ?? "";
              }) as GridValueGetter<DailyDiarySearchResourceRecord>,
            },
          ]
        : []),
      ...(withCompanyColumn
        ? [
            {
              field: "company",
              headerName: t("Projects.DailyDiaries.Resource.company"),
              resizable: true,
              valueGetter: ((_, row) => {
                return row.company ?? "";
              }) as GridValueGetter<DailyDiarySearchResourceRecord>,
            },
          ]
        : []),
      {
        field: "quantity",
        headerName: t("Projects.DailyDiaries.Resource.quantity"),
        width: 70,
        resizable: true,
      },
      {
        field: "hours",
        headerName: t("Projects.DailyDiaries.Resource.hours"),
        width: 55,
        resizable: true,
        valueGetter: (_, row) => row.hours,
        valueFormatter: ((value) =>
          timeStripMinutes(value)) as GridValueFormatter<
          DailyDiarySearchResourceRecord,
          any,
          any,
          string
        >,
      },
      {
        field: "totalHours",
        headerName: t("Projects.DailyDiaries.Resource.totalHours"),
        width: 90,
        resizable: true,
        valueGetter: (_, row) =>
          datetimeToHoursAndMinutes(
            new Date(`2024-01-01 ${row.hours}`).toString(),
            row.quantity ?? 1
          ),
      },
      {
        field: "remarks",
        headerName: t("Projects.DailyDiaries.Resource.remarks"),
        flex: 0.25,
        resizable: true,
        renderCell: (
          params: GridRenderCellParams<DailyDiarySearchResourceRecord, any, any>
        ) => (
          <DescriptionColumnRenderer
            description={params.row.remarks ?? undefined}
          />
        ),
      },
      ...(withTeamColumn
        ? [
            {
              field: "team",
              headerName: t("Projects.DailyDiaries.Resource.team"),
              resizable: true,
              valueGetter: ((_, row) =>
                row.team ??
                "") as GridValueGetter<DailyDiarySearchResourceRecord>,
            },
          ]
        : []),
      creatorCol,
      attachmentsCol,
    ];
  }, [
    t,
    withShiftColumn,
    withCompanyColumn,
    withTeamColumn,
    attachmentsCol,
    diaryDateCol,
    diaryStatusCol,
    creatorCol,
  ]);
};
