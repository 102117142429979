import { css, Stack, styled, Typography, useTheme } from "@mui/material";
import {
  DateRange,
  DateRangePicker,
  DateRangeValidationError,
} from "@mui/x-date-pickers-pro";
import { FormErrorLabel } from "components/FormErrorLabel";
import moment from "moment";
import { CaretRight } from "phosphor-react";
import { useTranslation } from "react-i18next";

type DiaryDateRangePickerProps = {
  minStartDate?: Date;
  disabled?: boolean;
  value?: DateRange<Date>;
  hasError?: boolean;
  onChange: (value: DateRange<Date>) => void;
  onErrorChange: (hasError: boolean) => void;
};

const StyledDateRangePicker = styled(DateRangePicker)(
  ({ theme }) => css`
    & {
      & > svg {
        margin-left: ${theme.spacing(1)} !important;
      }

      & > div:nth-of-type(2) {
        margin-left: ${theme.spacing(1)};
      }
    }
  `
);

export const DiaryDateRangePicker: React.FC<DiaryDateRangePickerProps> = ({
  value,
  minStartDate,
  disabled,
  hasError,
  onChange,
  onErrorChange,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleOnChange = (value: DateRange<Date>) => {
    onErrorChange(!value[0] || !value[1]);
    onChange(value);
  };

  return (
    <Stack direction="column" spacing={1} px={3}>
      <Typography variant="p2" fontWeight={600} color="grey.800">
        {t("Projects.DailyDiariesSearch.dateRange")}
      </Typography>
      <Stack direction="column" spacing={0.5}>
        <StyledDateRangePicker
          minDate={minStartDate}
          disableFuture
          disabled={disabled}
          formatDensity="dense"
          format="yyyy-MM-dd"
          value={value}
          onChange={handleOnChange}
          label={null}
          onError={(
            error: DateRangeValidationError,
            value: DateRange<Date>
          ) => {
            if (
              error[0] === "invalidRange" &&
              moment(value[0]).isSame(moment(value[1]), "days")
            ) {
              // Note: this seems to be a known issue on the MUI side https://github.com/mui/material-ui-pickers/issues/1759
              // Solution: if date is the same, consider there are no errors
              onChange([
                moment(value[0]).startOf("day").toDate(),
                moment(value[1]).startOf("day").toDate(),
              ]);
              onErrorChange(false);
              return;
            }
            const hasErrors = error.filter((err) => !!err).length > 0;
            const missingValue = !value[0] || !value[1];
            onErrorChange(!!hasErrors || missingValue);
          }}
          slotProps={{
            field: {
              slots: {
                separator: () => (
                  <CaretRight size={36} color={theme.palette.grey[800]} />
                ),
              },
            },
            textField: {
              size: "small",
              placeholder: "YYYY-MM-DD",
              error: hasError,
              label: null,
            },
          }}
        />
        {hasError && (
          <FormErrorLabel
            dataTestId="date-range-picker-error"
            errorMessage={t("common.errorMessages.invalidDateRange")}
          />
        )}
      </Stack>
    </Stack>
  );
};
