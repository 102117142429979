import {
  Box,
  Divider,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { ItemTag } from "components/ItemTag";
import {
  StatusOptionNew,
  StatusTagNew,
} from "components/StatusTag/StatusTagNew";
import { ChangeProductItemStatusModal } from "containers/Projects/components/ChangeProductItemStatusModal/ChangeProductItemStatusModal";
import { ProductItemHeaderContainer } from "containers/Projects/components/HeaderContainer";
import { ItemStatusOption, ProductType } from "generated/graphql";
import { useMemo, useState } from "react";
import { HeaderBackButton } from "./HeaderBackButton";
import { RecordedMark } from "../RecordedMark";
import { OverflowTooltip } from "components/OverflowTooltip";
import { HeaderLinks, HeaderLinksProps } from "./HeaderLinks";

export type EWInstructionDetailsHeaderProps = {
  title: string;
  productType: ProductType.EarlyWarnings | ProductType.Instructions;
  statusOptions: ItemStatusOption[];
  statusOptionId: string;
  itemNumber?: string;
  dataLoading?: boolean;
  isRecorded?: boolean;
  onStatusChange?: (
    newStatus: ItemStatusOption,
    reasonId: string,
    remarks?: string
  ) => void;
  onBack: () => void;
} & HeaderLinksProps;

export const EWInstructionDetailsHeader: React.FC<
  EWInstructionDetailsHeaderProps
> = ({
  title,
  itemNumber,
  dataLoading,
  productType,
  statusOptionId,
  statusOptions,
  isRecorded,
  attachmentsCount,
  commentsCount,
  hasMoreComments,
  onAttachmentsClick,
  onCommentsClick,
  onStatusChange,
  onBack,
}) => {
  const theme = useTheme();

  const [changeStatusModalOpen, setChangeStatusModalOpen] = useState(false);
  const [newStatus, setNewStatus] = useState<ItemStatusOption>();

  const sortedStatusOptions = useMemo(() => {
    const sortedOptions = statusOptions.slice();
    sortedOptions.sort((op1, op2) => op1.displayOrder - op2.displayOrder);
    return sortedOptions;
  }, [statusOptions]);

  const triggerStatusChangeFlow = (
    newSelectedOption: StatusOptionNew<string>
  ) => {
    setNewStatus(
      sortedStatusOptions.find((option) => option.id === newSelectedOption.id)!
    );

    setChangeStatusModalOpen(true);
  };

  const handleStatusChange = (reasonId: string, remarks?: string) => {
    onStatusChange?.(newStatus!, reasonId, remarks);
    setChangeStatusModalOpen(false);
    setNewStatus(undefined);
  };

  const showHeaderLinks =
    (attachmentsCount && attachmentsCount > 0) ||
    (commentsCount && commentsCount > 0);

  return (
    <>
      <ChangeProductItemStatusModal
        open={changeStatusModalOpen}
        oldStatusName={
          sortedStatusOptions.find((option) => option.id === statusOptionId)
            ?.description || ""
        }
        newStatusName={newStatus?.description || ""}
        reasons={newStatus?.optionReasons.items || []}
        onClose={() => setChangeStatusModalOpen(false)}
        onSecondaryClick={() => setChangeStatusModalOpen(false)}
        onPrimaryClick={handleStatusChange}
      />
      <ProductItemHeaderContainer>
        <>
          <HeaderBackButton onBack={onBack} disabled={dataLoading} />
          {dataLoading ? (
            <Box ml={2}>
              <Skeleton variant="rectangular" animation="pulse" width={500} />
            </Box>
          ) : (
            <Box display="flex" alignItems="center" ml={2} flex={1}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Stack
                  direction="column"
                  alignItems="flex-start"
                  spacing={0.25}
                >
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <ItemTag
                      type={productType}
                      borderRadius="6px"
                      height="20px !important"
                    />
                    <Typography variant="p2" color="grey.600">
                      {itemNumber}
                    </Typography>
                  </Stack>
                  <OverflowTooltip
                    title={title}
                    data-testid={`item-title-${title}`}
                    typographyProps={{
                      variant: "h3",
                      color: "grey.800",
                      lineHeight: "24px",
                      maxWidth: "500px",
                    }}
                  />
                </Stack>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    my: "14px !important",
                    pl: theme.spacing(1),
                  }}
                />
                <Box display="flex" alignItems="center" pl={1}>
                  <StatusTagNew
                    size="small"
                    hideArrow
                    placement="bottom-start"
                    selectedOptionId={statusOptionId}
                    options={sortedStatusOptions.map((statusOption) => ({
                      id: statusOption.id,
                      label: statusOption.description,
                      style: statusOption.style,
                    }))}
                    disabled={!onStatusChange}
                    onChange={triggerStatusChangeFlow}
                  />
                </Box>
              </Stack>
              {isRecorded && <RecordedMark />}
              {showHeaderLinks ? (
                <Box ml="auto">
                  <HeaderLinks
                    attachmentsCount={attachmentsCount}
                    commentsCount={commentsCount}
                    onAttachmentsClick={onAttachmentsClick}
                    onCommentsClick={onCommentsClick}
                    hasMoreComments={hasMoreComments}
                  />
                </Box>
              ) : null}
              {/* NOTE: uncomment when implementing Copy functionality. `isHeaderStuck` is coming from ProductItemHeaderContainer if children are a function */}
              {/* <Box ml={showHeaderLinks ? 4 : 'auto'} mr={isHeaderStuck ? 3 : 0}>
                  <IconButton
                    onClick={() => {}}
                    style={{
                      background: "transparent",
                      border: "1px solid grey",
                      borderRadius: "8px",
                      borderColor: theme.palette.grey[300],
                    }}
                    size="medium"
                  >
                    <Copy size={24} />
                  </IconButton>
                </Box> */}
            </Box>
          )}
        </>
      </ProductItemHeaderContainer>
    </>
  );
};
