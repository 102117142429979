import { useGraphLazyQueryLite } from "hooks/useGraphLazyQueryLite";
import { DDSection } from "../../DailyDiary/components/DailyDiaryInterpretor/DDSectionsInterpretor";
import {
  dailyDiaryDelayRecordsAttachmentsQuery,
  dailyDiaryEquipmentRecordsAttachmentsQuery,
  dailyDiaryGeneralRecordsAttachmentsQuery,
  dailyDiaryHSERecordsAttachmentsQuery,
  dailyDiaryManpowerRecordsAttachmentsQuery,
  dailyDiaryWeatherRecordsAttachmentsQuery,
  dailyDiaryWorkRecordsAttachmentsQuery,
} from "../DailyDiariesSearch.query";
import {
  Attachment,
  AttachmentStatus,
  DailyDiaryDelayRecordsAttachmentsQuery,
  DailyDiaryDelayRecordsAttachmentsQueryVariables,
  DailyDiaryEquipmentRecordsAttachmentsQuery,
  DailyDiaryEquipmentRecordsAttachmentsQueryVariables,
  DailyDiaryGeneralRecordsAttachmentsQuery,
  DailyDiaryGeneralRecordsAttachmentsQueryVariables,
  DailyDiaryHseRecordsAttachmentsQuery,
  DailyDiaryHseRecordsAttachmentsQueryVariables,
  DailyDiaryManpowerRecordsAttachmentsQuery,
  DailyDiaryManpowerRecordsAttachmentsQueryVariables,
  DailyDiaryWeatherRecordsAttachmentsQuery,
  DailyDiaryWeatherRecordsAttachmentsQueryVariables,
  DailyDiaryWorkRecordsAttachmentsQuery,
  DailyDiaryWorkRecordsAttachmentsQueryVariables,
} from "generated/graphql";
import { useCallback, useEffect, useState } from "react";

export const useRecordActiveAttachments = (
  recordId?: string,
  dailyDiaryId?: string,
  section?: DDSection,
  revisionId?: string
) => {
  const [fetchManpowerRecords] = useGraphLazyQueryLite<
    DailyDiaryManpowerRecordsAttachmentsQuery,
    DailyDiaryManpowerRecordsAttachmentsQueryVariables
  >(dailyDiaryManpowerRecordsAttachmentsQuery);

  const [fetchEquipmentRecords] = useGraphLazyQueryLite<
    DailyDiaryEquipmentRecordsAttachmentsQuery,
    DailyDiaryEquipmentRecordsAttachmentsQueryVariables
  >(dailyDiaryEquipmentRecordsAttachmentsQuery);

  const [fetchWorkRecords] = useGraphLazyQueryLite<
    DailyDiaryWorkRecordsAttachmentsQuery,
    DailyDiaryWorkRecordsAttachmentsQueryVariables
  >(dailyDiaryWorkRecordsAttachmentsQuery);

  const [fetchDelayRecords] = useGraphLazyQueryLite<
    DailyDiaryDelayRecordsAttachmentsQuery,
    DailyDiaryDelayRecordsAttachmentsQueryVariables
  >(dailyDiaryDelayRecordsAttachmentsQuery);

  const [fetchGeneralRecords] = useGraphLazyQueryLite<
    DailyDiaryGeneralRecordsAttachmentsQuery,
    DailyDiaryGeneralRecordsAttachmentsQueryVariables
  >(dailyDiaryGeneralRecordsAttachmentsQuery);

  const [fetchHSERecords] = useGraphLazyQueryLite<
    DailyDiaryHseRecordsAttachmentsQuery,
    DailyDiaryHseRecordsAttachmentsQueryVariables
  >(dailyDiaryHSERecordsAttachmentsQuery);

  const [fetchWeatherRecords] = useGraphLazyQueryLite<
    DailyDiaryWeatherRecordsAttachmentsQuery,
    DailyDiaryWeatherRecordsAttachmentsQueryVariables
  >(dailyDiaryWeatherRecordsAttachmentsQuery);

  const [attachments, setAttachments] = useState<Attachment[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchRecords = useCallback(
    async (
      dailyDiaryId: string,
      section: DDSection,
      recordId: string,
      revisionId: string
    ) => {
      setLoading(true);

      switch (section) {
        case DDSection.Delays: {
          const { data } = await fetchDelayRecords(
            { dailyDiaryId, revisionId },
            "cache-first"
          );
          if (data) {
            setAttachments(
              (
                data.dailyDiaryDelayRecords.find(
                  (crtRecord) => crtRecord.id === recordId
                )?.attachments as Attachment[] | undefined
              )?.filter(
                (attachment) => attachment.status === AttachmentStatus.Active
              ) ?? []
            );
          }
          break;
        }
        case DDSection.General: {
          const { data } = await fetchGeneralRecords(
            { dailyDiaryId, revisionId },
            "cache-first"
          );
          if (data) {
            setAttachments(
              (
                data.dailyDiaryGeneralRecords.find(
                  (crtRecord) => crtRecord.id === recordId
                )?.attachments as Attachment[] | undefined
              )?.filter(
                (attachment) => attachment.status === AttachmentStatus.Active
              ) ?? []
            );
          }
          break;
        }
        case DDSection.HSE: {
          const { data } = await fetchHSERecords(
            { dailyDiaryId, revisionId },
            "cache-first"
          );
          if (data) {
            setAttachments(
              (
                data.dailyDiaryHSERecords.find(
                  (crtRecord) => crtRecord.id === recordId
                )?.attachments as Attachment[] | undefined
              )?.filter(
                (attachment) => attachment.status === AttachmentStatus.Active
              ) ?? []
            );
          }
          break;
        }
        case DDSection.Work: {
          const { data } = await fetchWorkRecords(
            { dailyDiaryId, revisionId },
            "cache-first"
          );
          if (data) {
            setAttachments(
              (
                data.dailyDiaryWorkRecords.find(
                  (crtRecord) => crtRecord.id === recordId
                )?.attachments as Attachment[] | undefined
              )?.filter(
                (attachment) => attachment.status === AttachmentStatus.Active
              ) ?? []
            );
          }
          break;
        }
        case DDSection.Equipment: {
          const { data } = await fetchEquipmentRecords(
            { dailyDiaryId, revisionId },
            "cache-first"
          );
          if (data) {
            setAttachments(
              (
                data.dailyDiaryEquipmentRecords.find(
                  (crtRecord) => crtRecord.id === recordId
                )?.attachments as Attachment[] | undefined
              )?.filter(
                (attachment) => attachment.status === AttachmentStatus.Active
              ) ?? []
            );
          }
          break;
        }
        case DDSection.Manpower: {
          const { data } = await fetchManpowerRecords(
            { dailyDiaryId, revisionId },
            "cache-first"
          );
          if (data) {
            setAttachments(
              (
                data.dailyDiaryManpowerRecords.find(
                  (crtRecord) => crtRecord.id === recordId
                )?.attachments as Attachment[] | undefined
              )?.filter(
                (attachment) => attachment.status === AttachmentStatus.Active
              ) ?? []
            );
          }
          break;
        }
        case DDSection.Weather: {
          const { data } = await fetchWeatherRecords(
            { dailyDiaryId, revisionId },
            "cache-first"
          );
          if (data) {
            setAttachments(
              (
                data.dailyDiaryWeatherRecords.find(
                  (crtRecord) => crtRecord.id === recordId
                )?.attachments as Attachment[] | undefined
              )?.filter(
                (attachment) => attachment.status === AttachmentStatus.Active
              ) ?? []
            );
          }
          break;
        }
        default:
          break;
      }

      setLoading(false);
    },
    [
      fetchDelayRecords,
      fetchEquipmentRecords,
      fetchGeneralRecords,
      fetchHSERecords,
      fetchManpowerRecords,
      fetchWeatherRecords,
      fetchWorkRecords,
    ]
  );

  useEffect(() => {
    if (dailyDiaryId && section && recordId && revisionId) {
      fetchRecords(dailyDiaryId, section, recordId, revisionId);
    }
  }, [dailyDiaryId, section, recordId, revisionId, fetchRecords]);

  return {
    attachments,
    loading,
  };
};
