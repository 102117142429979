import { DailyDiarySearchHseRecord } from "generated/graphql";
import { TFunction } from "i18next";

export const toRecordJSON = (
  record: DailyDiarySearchHseRecord,
  t: TFunction
) => {
  return {
    [t("common.labels.description")]: record.description ?? "",
    [t("Projects.DailyDiaries.HSE.HSEType")]: record.hseType ?? "",
    [t("common.labels.createdBy")]: record.creator,
  };
};
