import { TFunction } from "i18next";
import { DailyDiarySearchResourceRecord } from "./ResourceSectionSearchResults";
import { datetimeToHoursAndMinutes } from "containers/Projects/containers/DailyDiary/components/ManpowerSection/ResourceRecordModal/ResourceRecordForm.utils";
import { timeStripMinutes } from "containers/Projects/containers/DailyDiary/utilts";

export const toRecordJSON = ({
  record,
  withCompanyColumn,
  withShiftColumn,
  withTeamColumn,
  t,
}: {
  record: DailyDiarySearchResourceRecord;
  withShiftColumn: boolean;
  withCompanyColumn: boolean;
  withTeamColumn: boolean;
  t: TFunction;
}) => {
  return {
    [t("Projects.DailyDiaries.Resource.resource")]: record.resource ?? "",
    ...(withShiftColumn && {
      [t("Projects.DailyDiaries.Resource.shift")]: record.shift ?? "",
    }),
    [t("Projects.DailyDiaries.Resource.quantity")]: record.quantity ?? "",
    [t("Projects.DailyDiaries.Resource.hours")]: timeStripMinutes(record.hours),
    [t("Projects.DailyDiaries.Resource.totalHours")]: datetimeToHoursAndMinutes(
      new Date(`2024-01-01 ${record.hours}`).toString(),
      record.quantity ?? 1
    ),
    [t("Projects.DailyDiaries.Resource.remarks")]: record.remarks ?? "",
    ...(withCompanyColumn && {
      [t("Projects.DailyDiaries.Resource.company")]: record.company ?? "",
    }),
    ...(withTeamColumn && {
      [t("Projects.DailyDiaries.Resource.team")]: record.team ?? "",
    }),
    [t("common.labels.createdBy")]: record.creator,
  };
};
