import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import { FormErrorLabel } from "components/FormErrorLabel";
import { DailyDiarySearchRecordStatus } from "generated/graphql";
import { FormEventHandler } from "react";
import { useTranslation } from "react-i18next";

type StatusPickerProps = {
  value?: DailyDiarySearchRecordStatus[];
  hasError?: boolean;
  onChange: (value: DailyDiarySearchRecordStatus[]) => void;
};

const orderedStatuses: Record<
  DailyDiarySearchRecordStatus,
  DailyDiarySearchRecordStatus
> = {
  [DailyDiarySearchRecordStatus.Approved]:
    DailyDiarySearchRecordStatus.Approved,
  [DailyDiarySearchRecordStatus.Sent]: DailyDiarySearchRecordStatus.Sent,
  [DailyDiarySearchRecordStatus.Rejected]:
    DailyDiarySearchRecordStatus.Rejected,
};

export const StatusPicker: React.FC<StatusPickerProps> = ({
  value = [],
  hasError,
  onChange,
}) => {
  const { t } = useTranslation();

  const handleCheckboxChange: FormEventHandler<HTMLDivElement> = (data) => {
    const updatedStatus = (data.target as any)
      .name as DailyDiarySearchRecordStatus;
    const isChecked = (data.target as any).checked;

    const newValue = isChecked
      ? [...value, updatedStatus]
      : value.filter((status) => status !== updatedStatus);

    onChange(newValue);
  };

  return (
    <Stack direction="column" spacing={1} px={3}>
      <Typography variant="p2" fontWeight={600} color="grey.800">
        {t("Projects.DailyDiariesSearch.status")}
      </Typography>
      <Stack direction="column">
        <FormGroup onChange={handleCheckboxChange}>
          {Object.values(orderedStatuses).map((status) => (
            <FormControlLabel
              key={status}
              control={
                <Checkbox
                  size="small"
                  name={status}
                  checked={value?.includes(status)}
                  sx={{ height: "30px" }}
                />
              }
              label={
                <Typography variant="p2" color="grey.800">
                  {t(`Projects.DailyDiariesSearch.statuses.${status}`)}
                </Typography>
              }
            />
          ))}
        </FormGroup>
      </Stack>
      {hasError && (
        <FormErrorLabel
          dataTestId="status-picker-error"
          errorMessage={t("common.errorMessages.requiredField")}
        />
      )}
    </Stack>
  );
};
