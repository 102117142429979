import { Divider, Grid, Stack, Typography, useTheme } from "@mui/material";
import { StatusTagNew } from "components/StatusTag/StatusTagNew";
import {
  authorizationAuditTrailStatusOptions,
  diaryAuthorizationAuditTrailStatusOptions,
} from "../../../../../containers/Home/components/Authorizations/Authorizations.constants";
import {
  AuthorizationActionStatus,
  AuthorizationWorkflowAuditStatus,
  AuthorizationWorkflowAuditTrail,
  User,
} from "generated/graphql";
import { getUserNameOrEmail } from "helpers/miscelaneous";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import { DateWithTimeTooltip } from "containers/Projects/components/DateWithTimeTooltip";
import { OverflowTooltip } from "components/OverflowTooltip";
import { LocalAuthorizationWorkflowAuditStatus } from "./AuthorizationLevel";

export type AuthorizationLevelOverviewRecordProps = {
  user: User;
  auditTrail?: AuthorizationWorkflowAuditTrail;
  levelStatus: LocalAuthorizationWorkflowAuditStatus;
  authAuditStatus: AuthorizationWorkflowAuditStatus;
  contractTimezone: string;
  dailyDiaryVariant?: boolean;
};

/**
 * New dailyDiaryVariant - possibly to become the new norm
 */
export const AuthorizationLevelOverviewRecord: React.FC<
  AuthorizationLevelOverviewRecordProps
> = ({
  user,
  auditTrail,
  levelStatus,
  authAuditStatus,
  contractTimezone,
  dailyDiaryVariant = false,
}) => {
  const theme = useTheme();

  const settledStatuses: LocalAuthorizationWorkflowAuditStatus[] = [
    AuthorizationWorkflowAuditStatus.Authorized,
    AuthorizationWorkflowAuditStatus.Denied,
    AuthorizationWorkflowAuditStatus.Removed,
  ];
  const levelOrAuthAuditSettled =
    settledStatuses.includes(levelStatus) ||
    settledStatuses.includes(authAuditStatus);
  const levelNotStarted = levelStatus === "NotStarted";
  const doNotShowStatus =
    levelNotStarted ||
    (levelOrAuthAuditSettled &&
      (!auditTrail?.status ||
        auditTrail.status === AuthorizationActionStatus.Pending));

  return dailyDiaryVariant ? (
    <Stack px={2} spacing={2} py={1}>
      <Stack direction="row" alignItems="center" height="24px">
        <OverflowTooltip
          typographyProps={{
            variant: "p2",
            color: "grey.800",
            maxWidth: "175px",
          }}
          title={getUserNameOrEmail(user)}
        />
        {doNotShowStatus ? null : (
          <Stack direction="row" alignItems="center" ml="auto" spacing={1}>
            {auditTrail?.dateCreated ? (
              <DateWithTimeTooltip
                datetime={auditTrail.dateCreated}
                timezone={contractTimezone}
                variant="p3"
                color="grey.600"
              />
            ) : null}
            <StatusTagNew
              options={
                dailyDiaryVariant
                  ? diaryAuthorizationAuditTrailStatusOptions
                  : authorizationAuditTrailStatusOptions
              }
              selectedOptionId={
                auditTrail?.status ?? AuthorizationActionStatus.Pending
              }
              fixedWidth={dailyDiaryVariant}
              disabled
            />
          </Stack>
        )}
      </Stack>
      {auditTrail?.denialReason && (
        <Stack direction="row" spacing={2}>
          <Divider
            flexItem
            orientation="vertical"
            sx={{
              my: "4px !important",
              borderColor: theme.palette.error.main,
              borderWidth: "1px",
            }}
          />
          <RichTextArea
            readOnly
            content={auditTrail.denialReason}
            color={theme.palette.grey[800]}
          />
        </Stack>
      )}
    </Stack>
  ) : (
    <Grid container py={1} px={3} data-testid={`authorizer-${user.id}`}>
      <Grid item xs={4}>
        <Typography variant="p2" color="grey.800">
          {getUserNameOrEmail(user)}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        {levelOrAuthAuditSettled &&
        (!auditTrail?.status ||
          auditTrail.status === AuthorizationActionStatus.Pending) ? null : (
          <StatusTagNew
            options={authorizationAuditTrailStatusOptions}
            selectedOptionId={
              auditTrail?.status ?? AuthorizationActionStatus.Pending
            }
            disabled
          />
        )}
      </Grid>
      <Grid item xs={2}>
        {auditTrail?.dateCreated ? (
          <DateWithTimeTooltip
            datetime={auditTrail.dateCreated}
            timezone={contractTimezone}
            variant="p2"
            color="grey.600"
          />
        ) : (
          <Typography variant="p2" color="grey.600">
            -
          </Typography>
        )}
      </Grid>
      <Grid item xs={4}>
        <RichTextArea
          readOnly
          content={auditTrail?.denialReason ?? "-"}
          color={theme.palette.grey[600]}
        />
      </Grid>
    </Grid>
  );
};
