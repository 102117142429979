import { DailyDiarySearchWorkRecord } from "generated/graphql";
import { TFunction } from "i18next";

export const toRecordJSON = (
  record: DailyDiarySearchWorkRecord,
  withShiftColumn: boolean,
  withActivityColumn: boolean,
  withAreaColumn: boolean,
  withDescriptionColumn: boolean,
  withDisciplineColumn: boolean,
  withTeamColumn: boolean,
  t: TFunction
) => {
  return {
    ...(withDescriptionColumn && {
      [t("common.labels.description")]: record.description ?? "",
    }),
    ...(withShiftColumn && {
      [t("Projects.DailyDiaries.Work.shift")]: record.shift ?? "",
    }),
    ...(withAreaColumn && {
      [t("Projects.DailyDiaries.Work.area")]: record.area ?? "",
    }),
    ...(withDisciplineColumn && {
      [t("Projects.DailyDiaries.Work.discipline")]: record.discipline ?? "",
    }),
    ...(withTeamColumn && {
      [t("Projects.DailyDiaries.Work.team")]: record.team ?? "",
    }),
    ...(withActivityColumn && {
      [t("Projects.DailyDiaries.Work.activity")]: record.activity ?? "",
    }),
    [t("Projects.DailyDiaries.Work.workType")]: record.workType ?? "",
    [t("Projects.DailyDiaries.Work.amount")]: record.amount
      ? `${record.amount}`
      : "",
    [t("Projects.DailyDiaries.Work.unitOfMeasurement")]:
      record.unitOfMeasurement ?? "",
    [t("common.labels.createdBy")]: record.creator,
  };
};
