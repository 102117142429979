import {
  Box,
  css,
  Stack,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DailyDiaryPresetSection } from "generated/graphql";
import { DelaySection } from "../DelaySection/DelaySection";
import { EquipmentSection } from "../EquipmentSection/EquipmentSection";
import { GeneralSection } from "../GeneralSection/GeneralSection";
import { HSESection } from "../HSESection/HSESection";
import { ManpowerSection } from "../ManpowerSection/ManpowerSection";
import { WeatherSection } from "../WeatherSection/WeatherSection";
import { WorkSection } from "../WorkSection/WorkSection";
import { SectionContainer } from "components/miscellaneous/SectionContainer";

export type DDSectionsInterpretorProps = {
  sections: DailyDiaryPresetSection[];
  showSections?: boolean;
  mainColumnExtraWidgetsBottom?: React.ReactNode;
  mainColumnExtraWidgetsFront?: React.ReactNode;
  secondaryColumnExtraWidgets?: React.ReactNode;
};

export enum DDSection {
  Weather = "Weather",
  Manpower = "Manpower",
  Equipment = "Equipment",
  Work = "Work",
  Delays = "Delays",
  HSE = "HSE",
  General = "General",
}

const DiarySection = styled(SectionContainer)(
  ({ theme }) => css`
    padding: ${theme.spacing(2)} 0;
  `
);

export const DDSectionsInterpretor: React.FC<DDSectionsInterpretorProps> = ({
  sections,
  showSections = true,
  mainColumnExtraWidgetsBottom,
  mainColumnExtraWidgetsFront,
  secondaryColumnExtraWidgets,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack
      spacing={3}
      alignItems="flex-start"
      direction={isMobile ? "column" : "row"}
      width="100%"
    >
      <Stack spacing={3} flex="3" overflow="hidden">
        {mainColumnExtraWidgetsFront && (
          <Box width="100%">{mainColumnExtraWidgetsFront}</Box>
        )}
        {showSections ? (
          <Box width="100%" key="records-section">
            <Stack spacing={2} width="100%">
              {sections.map((section) =>
                section.name === DDSection.Weather ? (
                  <DiarySection key={section.name}>
                    <WeatherSection key={section.name} section={section} />
                  </DiarySection>
                ) : section.name === DDSection.Manpower ? (
                  <DiarySection key={section.name}>
                    <ManpowerSection key={section.name} section={section} />
                  </DiarySection>
                ) : section.name === DDSection.Equipment ? (
                  <DiarySection key={section.name}>
                    <EquipmentSection key={section.name} section={section} />
                  </DiarySection>
                ) : section.name === DDSection.Work ? (
                  <DiarySection key={section.name}>
                    <WorkSection key={section.name} section={section} />
                  </DiarySection>
                ) : section.name === DDSection.Delays ? (
                  <DiarySection key={section.name}>
                    <DelaySection key={section.name} section={section} />
                  </DiarySection>
                ) : section.name === DDSection.HSE ? (
                  <DiarySection key={section.name}>
                    <HSESection key={section.name} section={section} />
                  </DiarySection>
                ) : section.name === DDSection.General ? (
                  <DiarySection key={section.name}>
                    <GeneralSection key={section.name} section={section} />
                  </DiarySection>
                ) : null
              )}
            </Stack>
          </Box>
        ) : null}
        {mainColumnExtraWidgetsBottom}
      </Stack>
      <Stack spacing={3} width={isMobile ? "100%" : "432px"}>
        {/* Changelog, reviewers, etc */}
        {secondaryColumnExtraWidgets}
      </Stack>
    </Stack>
  );
};
