import { Comment } from "../../../../generated/graphql";

export const computeCommentsCount = (comments: Comment[]) => {
  const commentsLength = comments.length;
  const repliesCount = comments.reduce((acc, crt) => {
    return crt.replies.items.length + acc;
  }, 0);

  return commentsLength + repliesCount;
};
