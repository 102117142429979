import {
  css,
  Divider,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { OverflowTooltip } from "components/OverflowTooltip";
import { User } from "generated/graphql";
import { getUserNameOrEmail } from "helpers/miscelaneous";
import { Check, Info } from "phosphor-react";
import { useTranslation } from "react-i18next";

type RoleSectionProps = {
  roleName: string;
  users: User[];
};

export enum UserRole {
  Viewer = "Viewer",
  Contributor = "Contributor",
  Creator = "Creator",
  Owner = "Owner",
}
const roleCapabilities: Record<UserRole, string[]> = {
  [UserRole.Viewer]: ["viewItems"],
  [UserRole.Contributor]: ["viewItems", "uploadAttachments", "addComments"],
  [UserRole.Creator]: [
    "viewItems",
    "uploadAttachments",
    "addComments",
    "createItems",
    "haveOwnership",
    "updateStatusTheyOwn",
    "editItemsTheyOwn",
  ],
  [UserRole.Owner]: [
    "viewItems",
    "uploadAttachments",
    "addComments",
    "createItems",
    "haveOwnership",
    "updateStatus",
    "editItems",
  ],
};

const TitleContainer = styled(Stack)(
  ({ theme }) => css`
    cursor: pointer;
    width: fit-content;
    height: 26px;
    flex-direction: row;
    align-items: center;
    gap: ${theme.spacing(0.75)};
  `
);

export const RoleSection: React.FC<RoleSectionProps> = ({
  roleName,
  users,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Stack direction="column">
      <Tooltip
        title={
          <Stack direction="column" spacing={0.5}>
            {roleCapabilities[roleName as UserRole].map((capability) => (
              <Stack
                direction="row"
                alignItems="center"
                spacing={0.75}
                key={capability}
              >
                <Check size={12} color={theme.palette.common.white} />
                <Typography variant="caption">
                  {t(`Projects.UsersAccessModal.permissions.${capability}`)}
                </Typography>
              </Stack>
            ))}
          </Stack>
        }
        placement="bottom-start"
      >
        <TitleContainer>
          <Typography
            variant="caption3"
            color="grey.800"
            letterSpacing="0.4px!important"
          >{`${
            users.length === 1
              ? roleName.toUpperCase()
              : `${roleName}s`.toUpperCase()
          } (${users.length})`}</Typography>
          <Info size={16} color={theme.palette.grey[600]} />
        </TitleContainer>
      </Tooltip>
      {users.map((user) => (
        <Stack direction="column" spacing={0} key={user.id}>
          <Stack
            direction="row"
            justifyContent="space-between"
            py={1.25}
            key={user.id}
          >
            <OverflowTooltip
              title={getUserNameOrEmail(user)}
              typographyProps={{
                variant: "p2",
                color: "grey.800",
              }}
            />
            <Typography
              variant="p3"
              color="grey.700"
              display="flex"
              alignItems="center"
            >
              {t(`Projects.UsersAccessModal.roles.${roleName}`)}
            </Typography>
          </Stack>
          <Divider
            orientation="horizontal"
            sx={{
              borderColor: theme.palette.grey[100],
            }}
          />
        </Stack>
      ))}
    </Stack>
  );
};
