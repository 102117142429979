import { GridSortModel } from "@mui/x-data-grid-pro";
import { useState } from "react";

export const useDiaryTableSorting = <T extends { dateCreated: string }>(
  records: T[]
) => {
  const [sortingModel, setSortingModel] = useState<GridSortModel>([]);

  return {
    rows: records,
    sortingModel,
    setSortingModel,
  };
};
