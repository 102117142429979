import {
  DailyDiaryDelayImportSourceItemsQuery,
  DailyDiaryDelayImportSourceItemsQueryVariables,
  DailyDiaryItem,
} from "generated/graphql";
import { useGraphLazyQueryLite } from "hooks/useGraphLazyQueryLite";
import { dailyDiaryDelayImportSourceItemsQuery } from "../DelaySection.query";

export const useAvailableDiariesToImportFrom = () => {
  const [
    fetchAvailableDiaries,
    { data: availableDiariesToImportFrom, loading },
  ] = useGraphLazyQueryLite<
    DailyDiaryDelayImportSourceItemsQuery,
    DailyDiaryDelayImportSourceItemsQueryVariables
  >(dailyDiaryDelayImportSourceItemsQuery);

  return {
    availableDiariesToImportFrom: (availableDiariesToImportFrom?.data
      .dailyDiaryDelayImportSourceItems.items ?? []) as DailyDiaryItem[],
    availableDiariesToImportFromLoading: loading,
    fetchAvailableDiaries,
  };
};
