import { DailyDiarySearchDelayRecord } from "generated/graphql";
import { TFunction } from "i18next";
import { timeStripMinutes } from "containers/Projects/containers/DailyDiary/utilts";

export const toRecordJSON = ({
  record,
  withAreaColumn,
  withDisciplineColumn,
  withTeamColumn,
  withShiftColumn,
  t,
}: {
  record: DailyDiarySearchDelayRecord;
  withShiftColumn: boolean;
  withAreaColumn: boolean;
  withDisciplineColumn: boolean;
  withTeamColumn: boolean;
  t: TFunction;
}) => {
  return {
    [t("common.labels.description")]: record.description ?? "",
    [t("Projects.DailyDiaries.Delay.delayType")]: record.delayType ?? "",
    ...(withShiftColumn && {
      [t("Projects.DailyDiaries.Delay.shift")]: record.shift ?? "",
    }),
    ...(withAreaColumn && {
      [t("Projects.DailyDiaries.Delay.area")]: record.area ?? "",
    }),
    ...(withDisciplineColumn && {
      [t("Projects.DailyDiaries.Delay.discipline")]: record.discipline ?? "",
    }),
    ...(withTeamColumn && {
      [t("Projects.DailyDiaries.Delay.team")]: record.team ?? "",
    }),
    [t("Projects.DailyDiaries.Delay.duration")]: timeStripMinutes(
      record.duration
    ),
    [t("common.labels.createdBy")]: record.creator,
  };
};
