import {
  Button,
  ButtonGroup,
  // ClickAwayListener,
  // Grow,
  // MenuItem,
  // MenuList,
  // Paper,
  // Popper,
  Typography,
  Stack,
  useTheme,
} from "@mui/material";
import { /* CaretDown, */ DownloadSimple } from "phosphor-react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

export enum DailyDiarySearchHeaderAction {
  Export = "common.buttons.export",
  ExportAll = "common.buttons.exportAll",
}

export const ExportButtons: React.FC<{
  loading?: boolean;
  canExport?: boolean;
  onClick: (selectedOption: string) => void;
}> = ({ loading, canExport, onClick }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  // const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const options = useMemo(
    () => [
      {
        // TODO: change order when enabling exportAll (i.e. remove this and uncomment last item)
        label: t(DailyDiarySearchHeaderAction.Export),
        icon: <DownloadSimple size={18} color={theme.palette.grey[700]} />,
      },
      {
        label: t(DailyDiarySearchHeaderAction.ExportAll),
        icon: <DownloadSimple size={18} color={theme.palette.grey[700]} />,
      },
      // {
      //   label: t(DailyDiarySearchHeaderAction.Export),
      //   icon: <DownloadSimple size={18} color={theme.palette.grey[700]} />,
      // },
    ],
    [t, theme]
  );

  const handleClick = (selectedIndex?: number) => {
    onClick(t(options[selectedIndex ?? 0].label));
  };

  // const handleMenuItemClick = (
  //   _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
  //   index: number
  // ) => {
  //   handleClick(index);
  //   setOpen(false);
  // };

  // const handleToggle = () => {
  //   setOpen((prevOpen) => !prevOpen);
  // };

  // const handleClose = (event: Event) => {
  //   if (
  //     anchorRef.current &&
  //     anchorRef.current.contains(event.target as HTMLElement)
  //   ) {
  //     return;
  //   }

  //   setOpen(false);
  // };

  // TODO: remove when implementing export all
  const exportBtnDisabled = loading || !canExport;

  return (
    <React.Fragment>
      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
        sx={{ borderRadius: "8px" }}
        disableElevation
        disableRipple
      >
        <Button
          size="small"
          sx={{
            height: "36px!important",
            px: theme.spacing(2.5),
            // paddingRight: theme.spacing(1.5), // TODO: uncomment paddings when enabling exportAll
            // paddingLeft: theme.spacing(2.5),
            py: theme.spacing(1),
          }}
          onClick={() => handleClick(0)}
          disabled={exportBtnDisabled}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <DownloadSimple
              size={16}
              color={theme.palette.grey[exportBtnDisabled ? 500 : 800]}
            />
            <Typography
              variant="p2"
              color={exportBtnDisabled ? "grey.500" : "grey.800"}
              fontWeight="bold"
            >
              {t(options[0].label)}
            </Typography>
          </Stack>
        </Button>
        {/* <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="export exportAll"
          aria-haspopup="menu"
          sx={{ py: 1, px: 1.5, height: "36px!important" }}
          onClick={handleToggle}
          disabled={loading}
        >
          <CaretDown size={20} color={theme.palette.grey[800]} />
        </Button> */}
      </ButtonGroup>
      {/* <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="split-button-menu"
                  autoFocusItem
                  sx={{ minWidth: "150px" }}
                >
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        {option.icon}
                        <Typography variant="p2" color="grey.800">
                          {t(option.label)}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper> */}
    </React.Fragment>
  );
};
