import {
  ProductInstanceDetailsLiteQuery,
  ProductInstanceDetailsLiteQueryVariables,
} from "generated/graphql";
import { productInstanceDetailsLiteQuery } from "../DailyDiariesSearch.query";
import { useGraphQuery } from "hooks/useGraphQuery";

export const useProductInstanceDetailsLite = (productInstanceId: string) => {
  const { data: productInstanceData, loading: productInstanceDataLoading } =
    useGraphQuery<
      ProductInstanceDetailsLiteQuery,
      ProductInstanceDetailsLiteQueryVariables
    >(productInstanceDetailsLiteQuery, {
      variables: { id: productInstanceId },
    });

  return {
    productInstanceData,
    productInstanceDataLoading,
  };
};
