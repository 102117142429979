import { gql } from "@apollo/client";

export const productInstanceUsersAccessQuery = gql`
  query productInstanceUsersAccess($id: ID!) {
    productInstance(id: $id) {
      id
      description
      status
      roles {
        items {
          userId
          status
          user {
            id
            firstname
            surname
            status
            email
          }
          productRole {
            id
            name
            status
          }
        }
      }
      product {
        id
        roles {
          items {
            id
            name
            status
          }
        }
      }
    }
  }
`;
