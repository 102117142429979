import {
  DailyDiaryExtraConfig,
  ProductInstanceStartDateDataQuery,
  ProductInstanceStartDateDataQueryVariables,
} from "generated/graphql";
import { productInstanceStartDateDataQuery } from "../DailyDiariesSearch.query";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useMemo } from "react";

export const useDiaryStartDate = (productInstanceId: string) => {
  const { data: productInstance, loading: productInstanceLoading } =
    useGraphQuery<
      ProductInstanceStartDateDataQuery,
      ProductInstanceStartDateDataQueryVariables
    >(productInstanceStartDateDataQuery, {
      variables: {
        id: productInstanceId!,
      },
      skip: !productInstanceId,
    });

  const startDate = useMemo(() => {
    if (!productInstance) {
      return null;
    }

    const contractStartDate = productInstance.productInstance.contract
      .startDate as string | null | undefined;

    const diaryStartDate = (
      productInstance.productInstance.extraConfig as DailyDiaryExtraConfig
    )?.startDate as string;

    return diaryStartDate ?? contractStartDate;
  }, [productInstance]);

  return {
    startDate,
    loading: productInstanceLoading,
  };
};
