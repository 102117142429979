import {
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridValueFormatter,
  GridValueGetter,
} from "@mui/x-data-grid-pro";
import {
  CompanyLookupCollection,
  DailyDiaryDelayRecord,
} from "generated/graphql";
import { useMemo } from "react";
import { useDailyDiaryStatus } from "../../../hooks/useDailyDiaryStatus";
import { DescriptionColumnRenderer } from "../../DescriptionColumnRenderer";
import { useTranslation } from "react-i18next";
import { useAttachmentsColumn } from "../../AttachmentsColumn";
import { useKebabButtonColumn } from "../../KebabButtonColumn";
import { useCreatedByColumn } from "../../CreatedByColumn";
import { timeStripMinutes } from "../../../utilts";
import { paddingColumnConfig } from "../../../DailyDiary.constants";

export const useColumns = ({
  delayLookups,
  withAreaColumn,
  withShiftColumn,
  withDisciplineColumn,
  withTeamColumn,
  withAttachments,
  onEdit,
  onRemove,
  onAttachmentsClick,
}: {
  onEdit?: (rowId: GridRowId) => void;
  onRemove?: (rowId: GridRowId) => void;
  onAttachmentsClick: (rowId: GridRowId) => void;
  withAttachments?: boolean;
  delayLookups: CompanyLookupCollection[];
  withAreaColumn?: boolean;
  withShiftColumn?: boolean;
  withDisciplineColumn?: boolean;
  withTeamColumn?: boolean;
}) => {
  const { isDraftOrEmptyDailyDiary } = useDailyDiaryStatus();
  const { t } = useTranslation();

  const attachmentsColumn =
    useAttachmentsColumn<DailyDiaryDelayRecord>(onAttachmentsClick);
  const kebabButtonColumn = useKebabButtonColumn<DailyDiaryDelayRecord>(
    onEdit,
    onRemove
  );
  const createdByColumn = useCreatedByColumn<DailyDiaryDelayRecord>();

  return useMemo((): GridColDef<DailyDiaryDelayRecord>[] => {
    return [
      paddingColumnConfig,
      {
        field: "description",
        headerName: t("common.labels.description"),
        flex: 0.25,
        resizable: true,
        renderCell: (
          params: GridRenderCellParams<any, DailyDiaryDelayRecord, any>
        ) => <DescriptionColumnRenderer description={params.row.description} />,
      },
      {
        field: "delayType",
        headerName: t("Projects.DailyDiaries.Delay.delayType"),
        resizable: true,
        valueGetter: (_, row) =>
          delayLookups
            .find((lkp) => lkp.id === row.delayTypeLookupId)
            ?.options.items.find(
              (option) => option.id === row.delayTypeOptionId
            )?.value,
      },
      ...(withShiftColumn
        ? [
            {
              field: "shift",
              headerName: t("Projects.DailyDiaries.Delay.shift"),
              resizable: true,
              valueGetter: ((_, row) => {
                return delayLookups
                  .find((lkp) => lkp.id === row.shiftLookupId)
                  ?.options.items.find(
                    (option) => option.id === row.shiftOptionId
                  )?.value;
              }) as GridValueGetter<DailyDiaryDelayRecord>,
            },
          ]
        : []),
      ...(withAreaColumn
        ? [
            {
              field: "area",
              headerName: t("Projects.DailyDiaries.Delay.area"),
              resizable: true,
              valueGetter: ((_, row) => {
                return delayLookups
                  .find((lkp) => lkp.id === row.areaLookupId)
                  ?.options.items.find(
                    (option) => option.id === row.areaOptionId
                  )?.value;
              }) as GridValueGetter<DailyDiaryDelayRecord>,
            },
          ]
        : []),
      ...(withDisciplineColumn
        ? [
            {
              field: "discipline",
              headerName: t("Projects.DailyDiaries.Delay.discipline"),
              resizable: true,
              valueGetter: ((_, row) => {
                return delayLookups
                  .find((lkp) => lkp.id === row.disciplineLookupId)
                  ?.options.items.find(
                    (option) => option.id === row.disciplineOptionId
                  )?.value;
              }) as GridValueGetter<DailyDiaryDelayRecord>,
            },
          ]
        : []),
      ...(withTeamColumn
        ? [
            {
              field: "team",
              headerName: t("Projects.DailyDiaries.Delay.team"),
              resizable: true,
              valueGetter: ((_, row) =>
                delayLookups
                  .find((lkp) => lkp.id === row.teamLookupId)
                  ?.options.items.find(
                    (option) => option.id === row.teamOptionId
                  )?.value) as GridValueGetter<DailyDiaryDelayRecord>,
            },
          ]
        : []),
      {
        field: "duration",
        headerName: t("Projects.DailyDiaries.Delay.duration"),
        maxWidth: 70,
        resizable: true,
        valueFormatter: ((value) =>
          timeStripMinutes(value)) as GridValueFormatter<
          DailyDiaryDelayRecord,
          any,
          any,
          string
        >,
      },
      createdByColumn,
      ...(withAttachments ? [attachmentsColumn] : []),
      ...(isDraftOrEmptyDailyDiary && (onRemove || onEdit)
        ? [{ ...kebabButtonColumn, width: 54 }]
        : []),
    ];
  }, [
    t,
    delayLookups,
    withAreaColumn,
    withShiftColumn,
    kebabButtonColumn,
    withDisciplineColumn,
    withTeamColumn,
    isDraftOrEmptyDailyDiary,
    attachmentsColumn,
    withAttachments,
    createdByColumn,
    onEdit,
    onRemove,
  ]);
};
