import { Box, css, Stack, styled, Typography, useTheme } from "@mui/material";
import { HeaderBackButton } from "containers/Projects/components/Header/HeaderBackButton";
import { useTranslation } from "react-i18next";
import { DailyDiarySearchHeaderAction, ExportButtons } from "./ExportButtons";

type HeaderProps = {
  loading?: boolean;
  canExport?: boolean;
  onExport: () => void;
  onExportAll: () => void;
};

const HeaderContainer = styled(Stack)(
  ({ theme }) => css`
    background: ${theme.palette.common.white};
    padding-left: ${theme.spacing(6)};
    padding-right: ${theme.spacing(6)};
    padding-top: ${theme.spacing(1.25)};
    padding-bottom: ${theme.spacing(1.25)};
    border-top: 1px solid ${theme.palette.grey[200]};
  `
);

export const Header: React.FC<HeaderProps> = ({
  loading,
  canExport,
  onExport,
  onExportAll,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleExportClick = (selectedOption: string) => {
    const clickedOptionAsEnum = selectedOption as DailyDiarySearchHeaderAction;

    switch (clickedOptionAsEnum) {
      case t(DailyDiarySearchHeaderAction.Export):
        onExport();
        break;
      case t(DailyDiarySearchHeaderAction.ExportAll):
        onExportAll();
        break;
      default:
        break;
    }
  };

  return (
    <HeaderContainer direction="row" alignItems="center">
      <Stack direction="row" alignItems="center" spacing={1.5}>
        <HeaderBackButton
          disabled={loading}
          sx={{ p: 0 }}
          iconColor={theme.palette.grey[600]}
        />
        <Typography variant="p1" color="grey.800">
          {t("Projects.DailyDiariesSearch.title")}
        </Typography>
      </Stack>

      <Box display="flex" alignItems="center" justifyContent="center" ml="auto">
        <ExportButtons onClick={handleExportClick} canExport={canExport} />
      </Box>
    </HeaderContainer>
  );
};
