import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { ProductItemHeaderContainer } from "containers/Projects/components/HeaderContainer";
import { ProductType } from "generated/graphql";
import { useTranslation } from "react-i18next";
import {
  // EditRiskEventContextMenu,
  EditRiskEventContextMenuProps,
} from "./EditRiskEventContextMenu";
import { HeaderLinks, HeaderLinksProps } from "../HeaderLinks";

export type EditRiskEventHeaderProps = {
  onCancel: () => void;
  onSave: () => void;
  productType?: ProductType;
  disabled?: boolean;
  loading?: boolean;
} & EditRiskEventContextMenuProps &
  HeaderLinksProps;

export const EditRiskEventHeader: React.FC<EditRiskEventHeaderProps> = ({
  onCancel,
  onSave,
  disabled,
  productType = ProductType.RisksRegister,
  loading,
  attachmentsCount,
  commentsCount,
  hasMoreComments,
  onAttachmentsClick,
  onCommentsClick,
  // onDuplicate,
  // onMoveToProject,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const showHeaderLinks =
    (attachmentsCount && attachmentsCount > 0) ||
    (commentsCount && commentsCount > 0);

  return (
    <ProductItemHeaderContainer height="72px">
      {(_isHeaderStuck?: boolean) => (
        <>
          <Typography
            variant="h3"
            color={theme.palette.grey[900]}
            data-testid="edit-title"
          >
            {productType === ProductType.RisksRegister
              ? t("Projects.Risks.editRisk")
              : t("Projects.Events.editEvent")}
          </Typography>
          {/* TODO: uncomment when implementing the functionality */}
          {/* <EditRiskEventContextMenu
            onDuplicate={onDuplicate}
            onMoveToProject={onMoveToProject}
          /> */}
          {showHeaderLinks ? (
            <Box ml="auto">
              <HeaderLinks
                attachmentsCount={attachmentsCount}
                commentsCount={commentsCount}
                onAttachmentsClick={onAttachmentsClick}
                onCommentsClick={onCommentsClick}
                hasMoreComments={hasMoreComments}
              />
            </Box>
          ) : null}
          <Box
            display="flex"
            alignItems="center"
            ml={showHeaderLinks ? 4 : "auto"}
            mr={0} // isHeaderStuck ? 3 : 0
          >
            <Button variant="text" onClick={onCancel} data-testid="cancel-btn">
              {t("common.buttons.cancel")}
            </Button>
            <Box ml={1}>
              <LoadingButton
                variant="contained"
                size="large"
                onClick={onSave}
                data-testid="save-btn"
                disabled={disabled || loading}
              >
                <Typography variant="p2" fontWeight={700}>
                  {t("common.labels.saveChanges")}
                </Typography>
              </LoadingButton>
            </Box>
          </Box>
        </>
      )}
    </ProductItemHeaderContainer>
  );
};
