import {
  Box,
  Divider,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { HeaderBackButton } from "containers/Projects/components/Header/HeaderBackButton";
import { ProductItemHeaderContainer } from "containers/Projects/components/HeaderContainer";
import {
  DailyDiaryItemMixed,
  DailyDiaryItemRevision,
  ProductType,
} from "generated/graphql";
import { DailyDiaryUserRole } from "hooks/useDailyDiaryUserBERole";
import { DDHeaderActionButtons } from "./DDHeaderActionButtons";
import { ItemTag } from "components/ItemTag";
import { OverflowTooltip } from "components/OverflowTooltip";
import { StatusTagNew } from "components/StatusTag/StatusTagNew";
import { isDailyDiaryItem } from "../../utilts";
import { dailyDiaryStatusOptions } from "containers/Projects/components/DailyDiariesView/DailyDiariesView.constants";
import { RevisionSelector } from "./RevisionSelector";
import {
  HeaderLinks,
  HeaderLinksProps,
} from "containers/Projects/components/Header/HeaderLinks";
import moment from "moment";
import { dailyDiaryDateFormat } from "../../DailyDiary.constants";

type DailyDiaryHeaderProps = {
  dailyDiary?: DailyDiaryItemMixed;
  dailyDiaryUserRoles?: DailyDiaryUserRole[];
  noActionButtons?: boolean;
  currentRevision?: DailyDiaryItemRevision;
  onBack?: () => void;
  onNextClick: () => void;
  onPreviousClick: () => void;
} & Omit<
  React.ComponentProps<typeof DDHeaderActionButtons>,
  "dailyDiaryUserRole" | "dailyDiary"
> &
  Omit<HeaderLinksProps, "attachmentsCount" | "onAttachmentsClick">;

export enum DailyDiaryHeaderAction {
  Send = "common.buttons.send",
  LockAndEdit = "Projects.DailyDiaries.lockAndEdit",
  Waive = "Projects.DailyDiaries.waive",
}

export const DailyDiaryHeader: React.FC<DailyDiaryHeaderProps> = ({
  dailyDiary,
  loading,
  dailyDiaryUserRoles,
  noActionButtons,
  currentRevision,
  onBack,
  onNextClick,
  onPreviousClick,
  commentsCount,
  hasMoreComments,
  onCommentsClick,
  ...restActionsProps
}) => {
  const theme = useTheme();

  const showHeaderLinks = commentsCount && commentsCount > 0;
  const showButtons = dailyDiaryUserRoles && dailyDiary && !noActionButtons;

  return (
    <ProductItemHeaderContainer height="72px">
      {/* NOTE: isHeaderStuck seems to not be necessary anymore? Not sure why... */}
      {/* {(isHeaderStuck?: boolean) => ( */}
      {() => (
        <>
          <HeaderBackButton onBack={onBack} disabled={loading} />
          {loading || !dailyDiary ? (
            <Box ml={2} height="44px" display="flex" alignItems="center">
              <Skeleton variant="rectangular" animation="pulse" width={500} />
            </Box>
          ) : (
            <Stack direction="row" spacing={2} alignItems="center" ml={2}>
              <Stack direction="column" alignItems="flex-start" spacing={0.25}>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <ItemTag
                    type={ProductType.DailyDiary}
                    borderRadius="6px"
                    height="20px !important"
                  />
                  {isDailyDiaryItem(dailyDiary) && (
                    <Typography variant="p2" color="grey.600">
                      {dailyDiary.number}
                    </Typography>
                  )}
                </Stack>
                <OverflowTooltip
                  title={moment(dailyDiary.date).format(dailyDiaryDateFormat)}
                  typographyProps={{
                    variant: "h3",
                    color: "grey.800",
                    lineHeight: "24px",
                    maxWidth: "500px",
                  }}
                />
              </Stack>
              {isDailyDiaryItem(dailyDiary) &&
                currentRevision &&
                dailyDiary.revisions.length > 1 && (
                  <RevisionSelector
                    dailyDiary={dailyDiary}
                    currentRevision={currentRevision}
                  />
                )}

              {currentRevision?.status && (
                <>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      my: "14px !important",
                      pl: theme.spacing(1),
                    }}
                  />
                  <Box display="flex" alignItems="center" pl={1}>
                    <StatusTagNew
                      selectedOptionId={currentRevision?.status}
                      disabled
                      options={dailyDiaryStatusOptions}
                    />
                  </Box>
                </>
              )}
            </Stack>
          )}
          {showHeaderLinks || showButtons ? (
            <Stack ml="auto" direction="row" alignItems="center" spacing={4}>
              {showHeaderLinks ? (
                <HeaderLinks
                  commentsCount={commentsCount}
                  hasMoreComments={hasMoreComments}
                  onCommentsClick={onCommentsClick}
                />
              ) : null}
              {dailyDiaryUserRoles && dailyDiary && !noActionButtons && (
                // mr={isHeaderStuck ? 3 : 0}
                <DDHeaderActionButtons
                  loading={loading}
                  dailyDiary={dailyDiary}
                  dailyDiaryUserRoles={dailyDiaryUserRoles}
                  dailyDiaryStatus={currentRevision?.status}
                  {...restActionsProps}
                />
              )}
            </Stack>
          ) : null}
        </>
      )}
    </ProductItemHeaderContainer>
  );
};
