import { useMemo } from "react";
import { DailyDiaryPresetSection } from "generated/graphql";
import { DelayOptionalColumn } from "../DelayTable/DelayTable";

export const useDelaySectionCommon = (section?: DailyDiaryPresetSection) => {
  const optionalColumns: DelayOptionalColumn[] = useMemo(() => {
    if (!section) {
      return [];
    }

    const columnsToReturn: DelayOptionalColumn[] = [];

    section.fields.forEach((field) => {
      switch (field.name) {
        case "Area":
          !field.isHidden && columnsToReturn.push(DelayOptionalColumn.Area);
          break;
        case "Discipline":
          !field.isHidden &&
            columnsToReturn.push(DelayOptionalColumn.Discipline);
          break;
        case "Team":
          !field.isHidden && columnsToReturn.push(DelayOptionalColumn.Team);
          break;
        case "Shift":
          !field.isHidden && columnsToReturn.push(DelayOptionalColumn.Shift);
          break;
      }
    });

    return columnsToReturn;
  }, [section]);

  const withDisciplineColumn = optionalColumns.includes(
    DelayOptionalColumn.Discipline
  );
  const withAreaColumn = optionalColumns.includes(DelayOptionalColumn.Area);
  const withTeamColumn = optionalColumns.includes(DelayOptionalColumn.Team);
  const withShiftColumn = optionalColumns.includes(DelayOptionalColumn.Shift);

  return {
    optionalColumns,
    withDisciplineColumn,
    withAreaColumn,
    withTeamColumn,
    withShiftColumn,
  };
};
