import { useMemo } from "react";
import { WorkOptionalColumn } from "./WorkTable/WorkTable";
import { DailyDiaryPresetSection } from "generated/graphql";

export const useWorkSectionCommon = (section?: DailyDiaryPresetSection) => {
  const optionalColumns: WorkOptionalColumn[] = useMemo(() => {
    if (!section) {
      return [];
    }

    const columnsToReturn: WorkOptionalColumn[] = [];

    section.fields.forEach((field) => {
      switch (field.name) {
        case "Description":
          !field.isHidden &&
            columnsToReturn.push(WorkOptionalColumn.Description);
          break;
        case "Area":
          !field.isHidden && columnsToReturn.push(WorkOptionalColumn.Area);
          break;
        case "Discipline":
          !field.isHidden &&
            columnsToReturn.push(WorkOptionalColumn.Discipline);
          break;
        case "Team":
          !field.isHidden && columnsToReturn.push(WorkOptionalColumn.Team);
          break;
        case "Shift":
          !field.isHidden && columnsToReturn.push(WorkOptionalColumn.Shift);
          break;
        case "Activity":
          !field.isHidden && columnsToReturn.push(WorkOptionalColumn.Activity);
          break;
      }
    });

    return columnsToReturn;
  }, [section]);

  return {
    optionalColumns,
  };
};
