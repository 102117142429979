import { GridColDef } from "@mui/x-data-grid-pro";

export const dailyDiaryDateFormat = "dddd yyyy-MM-DD";
export const dailyDiaryTimeFormat = "HH:mm:ss";

export const paddingColumnConfig: GridColDef = {
  field: "extra",
  headerName: "",
  maxWidth: 14,
  minWidth: 14,
  width: 14,
  resizable: false,
  hideSortIcons: true,
  disableColumnMenu: true,
  headerClassName: "padding-header",
  cellClassName: "padding-cell",
};
